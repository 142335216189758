const initialState = {
    data: [],
    loading: false,
    error: null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_DATA_SUCCESS_EDIT_USER':
            return { ...state, loading: false, data: action.payload, error: null };
      case 'ADD_DATA_FAILURE_EDIT_USER':
        return { ...state, loading: false, data: [], error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default reducer;