import React, { useEffect } from 'react';
import Logo from '../images/logo.png'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {useDispatch, useSelector } from 'react-redux';
import { navbarAction, fatchUserAPI} from "../redux/actionCreation";
import { Link,to, useNavigate } from 'react-router-dom';
function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchUserReducres = useSelector(state => state.fetchUserReducres);
  const username = fetchUserReducres.data ? fetchUserReducres.data.username : null;
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);

    // Fetch Quotations Data
    useEffect(() => {
      const fetchDataUser = async () => {
        try {
          const res = await dispatch(fatchUserAPI());
        } catch (error) {
          console.error('Error fetching quotations data:', error);
        }
      };
      fetchDataUser();
    }, []);

    useEffect(() => {
      const verify = async () => {
        try {
          // Check if fetchUserReducres.data is not null before accessing its properties
          if (fetchUserReducres.data && fetchUserReducres.data.expire_date) {
            const currentDate = new Date();
    
            const exp_data = new Date(fetchUserReducres.data.expire_date);
    
            if (currentDate > exp_data) {
              console.log("Token expired. Removing token.");
              localStorage.removeItem('tokan');
            } else {
              console.log("Token still valid.");
            }
          } else {
            console.warn("User data or expire_date is null or undefined.");
          }
        } catch (error) {
          console.error('Error verifying token:', error);
        }
      };
    
      verify();
    }, [fetchUserReducres.data?.expire_date]);

    const logoutFun = () =>{
      localStorage.removeItem('tokan');
    };

  return (
    <>
      <Navbar className="header-section">
          <Navbar.Brand className={`${props.addClass}`}>
            <Link to="/">
              <img src={Logo ? `${Logo}` : `${Logo}`} className="logo-size" alt="logo"/>
            </Link>
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={()=>{dispatch(navbarAction())}} className="toggle_button"><i className="fa fa-bars"></i></Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <div class="header-profile dropdown">
                <button className="dropdown-toggle btn btn-primary username" type="button"  data-bs-toggle="dropdown" aria-expanded="false">
                 {username}
                </button>
              
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                    <Link className="dropdown-item" to="/update-company"><i className="fa fa-user"></i> View Profile</Link>
                </li>
                <li><hr class="dropdown-divider"/></li>
                <li>
                    <Link className="dropdown-item" to="/login" onClick={logoutFun}>
                        <i className="fa fa-sign-out"></i> Logout
                    </Link>
                </li>
              </ul>
            </div>
          </Nav>
      </Navbar>
    </>
  );
}

export default Header;
