import React from 'react'
import {NavLink} from 'react-router-dom';
function FixedButton() {
  return (
    <>
        <div className="fixed">
            <NavLink to="/create-quotation" className="btn btn-info quot_btn">Quotation</NavLink>
            <NavLink to="/create-receipts" className="btn btn-info plus_btn"><i className="text-white fa fa-plus"></i></NavLink>
            <NavLink to="/create-bills" className="btn btn-info bill_btn">+ Bill / Invoice</NavLink>
        </div>
    </>
  )
}

export default FixedButton
