import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { TearmsAPI,fatchTearmsAPI,EditTearmsAPI } from '../../redux/actionCreation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import Loader from '../Loader';

function CompanyTearms() {

  const ref = useRef(null);
  const ref2 = useRef(null);
  const refEditTearms = useRef(null);
  const refEditClose = useRef(null);

  const [loading_update, setLoading_update] = useState(false);
  const [loading_create, setLoading_create] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const error = useSelector(state => state.AddTearmsReducers.error);

    const error_edit = useSelector(state => state.EditTearmsReducers.error);

    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({  
      tearms: '<ul><li>10% advance payment is required to confirm the booking.</li><li>100% payment is required before loading goods.</li><li>Payment option: NEFT/RTGS/IMPS/Credit Card/Debit Card/UPI.</li><li></li>Cancellation Charges 10% of Booking Amount.</ul>',
    });

    const resetForm = () => {
        setFormData({
            tearms: '',
        });
      };

      useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading_create(true);
    // Check if the tearms field is empty or contains only whitespace
    if (formDataUpdate.tearms.trim() === '<p><br></p>') {
      // Show error SweetAlert
      await Swal.fire({
        icon: 'error',
        title: 'Some Error',
        text: 'Terms and conditions cannot be blank.',
    });
      setLoading_create(false);
      return;
    }
    try {
      const res = await dispatch(TearmsAPI(formData));
      setLoading_create(false);
      if(res?.type == "ADD_DATA_TEARMS_SUCCESS"){
        ref2.current.click();
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Terms and Conditions updated successfully.',
          customClass: {
              container: 'my-custom-swal', // Add your custom class here
          },
      });
        dispatch(fatchTearmsAPI());
      }
      else {
        // Show error SweetAlert
          await Swal.fire({
              icon: 'error',
              title: 'Some Error',
              text: 'Failed to Add the Terms. Please try again.',
          });
      }
    } catch (error) {
    //   console.error('Error:', error);
    }
    
  };
    // Update tearms start
  const [formDataUpdate, setFormDataUpdate] = useState({  
    tearms: '',
});

  
  const UpdateBankClick = (companyId) => {
    console.log("ID is",companyId);
    setSelectedCompanyId(companyId);
    const selectedTearms = FeatchTearmsReducers.data.find(tearms => tearms.id === companyId);
    if (selectedTearms) {
      setFormDataUpdate({
            ...formDataUpdate,
            tearms: selectedTearms.tearms
          });
          console.log("dataaa",formDataUpdate);
    } else {
        console.log("Recipts not found");
    }
   
};

const UpdateBank = async (event) => {
    event.preventDefault();
    setLoading_update(true);
    if (formDataUpdate.tearms.trim() === '<p><br></p>') {
      await Swal.fire({
        icon: 'error',
        title: 'Some Error',
        text: 'Terms and conditions cannot be blank.',
    });
    setLoading_update(false);
      return;
    }
    try {
        const response = await dispatch(EditTearmsAPI(formDataUpdate, selectedCompanyId));
        setLoading_update(false);
        if(response?.type == "ADD_DATA_SUCCESS_EDIT_TEARMS"){
          refEditClose.current.click();
            await Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Company updated successfully.',
                customClass: {
                    container: 'my-custom-swal', // Add your custom class here
                },
            });
            dispatch(fatchTearmsAPI());
        }
          else {
          // Show error SweetAlert
            await Swal.fire({
                icon: 'error',
                title: 'Some Error',
                text: 'Failed to update the Company. Please try again.',
            });
        }
          
    } catch (error) {
      console.error('Error updating Company:', error);
    }
  };


  const handleChange = (value) => {
    setFormData({
      ...formData,
      tearms: value,
    });

  };

  const handleChangeUpdate = (value) => {
    setFormDataUpdate({
      ...formDataUpdate,
      tearms: value,
    });
  };

  const CompanyTearms = {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
    formats: [
      'bold', 'italic', 'underline',
      'list', 'bullet',
    ],
  };

  return (
    <>
      <Card>
              <div class="row p-4 pt-3">
              {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                [...FeatchTearmsReducers.data].reverse().map(item => (
                  <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Company Terms and Conditions</h5>
                      <hr class="w-10"/>
                      <div class="d-flex justify-content-between mt-3">
                          {/* <h6 class="text-dark profile_name_data">{item.tearms}</h6> */}
                          <h6 className="text-dark profile_name_data_terms" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                      </div>
                      <button ref={refEditTearms} class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#editTearmsModel" aria-controls="staticBackdrop" onClick={() => UpdateBankClick(item.id)}>
                          Edit Details+
                          </button>
                  </div>
                  ))
                  ) : (
                      <div className='text-center not_data mt-3'>
                          <i class="fa-solid fa-triangle-exclamation"></i>
                          <p>Add Company terms and Conditions</p>
                          <button ref={ref} class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop3" aria-controls="staticBackdrop">
                            Add+
                          </button>
                      </div>
                  )} 
              </div>
              {/* Add Bank Profile Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop3" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Add Terms and Conditions</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                          <ReactQuill
                              name="tearms"
                              value={formData.tearms}
                              onChange={handleChange}
                              modules={CompanyTearms.modules}
                              formats={CompanyTearms.formats}
                              placeholder="Write something..."
                            />
                          </Form.Group>
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                            {/* <Button variant="primary" type="submit" className='mt-3'>
                                Submit
                            </Button> */}
                            <div className='d-flex align-items-center justify-content-left'>
                              <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center mt-2'>
                                  <span className='mx-2'>Update</span>
                                  {loading_create && <span className='spin-sm'><Loader /></span>}
                              </Button>
                              <Button variant="secondary" type="reset" onClick={resetForm} className='mx-2 mt-2'>
                                  Reset
                              </Button>
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
                </div>
            </div>
            {/* Add Bank Profile End */}

            {/* Edit Bank Profile Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="editTearmsModel" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Terms and Conditions</h5>
                    <button type="button" ref={refEditClose} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                <Form onSubmit={UpdateBank}>
                    <Row className="mb-3">

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                          <ReactQuill
                              name="tearms"
                              value={formDataUpdate.tearms}
                              onChange={handleChangeUpdate}
                              modules={CompanyTearms.modules}
                              formats={CompanyTearms.formats}
                              placeholder="Write something..."
                            />
                          </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                            <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                <span className='mx-2'>Update</span>
                                {loading_update && <span className='spin-sm'><Loader /></span>}
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
                </div>
            </div>
            {/* Add Bank Profile End */}
      </Card>
            
    </>
  )
}

export default CompanyTearms
