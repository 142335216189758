const initialState = {
    data: [],
    loading: false,
    error: null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DATA_SUCCESS_PHOTO':
            return { ...state, loading: false, data: action.payload, error: null };
      case 'FETCH_DATA_FAILURE_PHOTO':
        return { ...state, loading: false, data: [], error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default reducer;