import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { bankAddAPI,fatchBankAPI,EditBankAPI } from '../../redux/actionCreation';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

function CompanyBank() {
  const ref = useRef(null);
  const ref2 = useRef(null);
  const refEditBank = useRef(null);
  const refEditClose = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading_update, setLoading_update] = useState(false);
  const [loading_create, setLoading_create] = useState(false);
  
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const error = useSelector(state => state.Add_bank_Reducers.error && state.Add_bank_Reducers.error.bank_add_errors);

    const error_edit = useSelector(state => state.EditBankReducers.error && state.EditBankReducers.error.bank_edit_errors);

    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({  
        account_holder_name: '',
        bank_name: '',
        account_number: '',
        iffc_code: '',
    });

    const resetForm = () => {
        setFormData({
            account_holder_name: '',
            bank_name: '',
            account_number: '',
            iffc_code: '',
        });
      };

      useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await dispatch(fatchBankAPI());
                setLoading(false);
                // Process the result if needed
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };
        fetchData(); // Call the async function
    }, []);

const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading_create(true);
    try {
      const res = await dispatch(bankAddAPI(formData));
      setLoading_create(false);
      if(res?.type == "ADD_DATA_BANK_SUCCESS"){
        ref2.current.click();
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Bank Details updated successfully.',
          customClass: {
              container: 'my-custom-swal', // Add your custom class here
          },
      });
        dispatch(fatchBankAPI());
      }
      else {
        // Show error SweetAlert
          await Swal.fire({
              icon: 'error',
              title: 'Some Error',
              text: 'Failed to Add the Bank. Please try again.',
          });
      }
    } catch (error) {
    //   console.error('Error:', error);
    }
    
  };

    // Update Bank start
  const [formDataUpdate, setFormDataUpdate] = useState({  
    account_holder_name: '',
    bank_name: '',
    account_number: '',
    iffc_code: '',
});

  
  const UpdateBankClick = (companyId) => {
    console.log("ID is",companyId);
    setSelectedCompanyId(companyId);
    const selectedBank = FetchBankReducers.data.find(bank => bank.id === companyId);
    if (selectedBank) {
      setFormDataUpdate({
            ...formDataUpdate,
            account_holder_name: selectedBank.account_holder_name,
            bank_name: selectedBank.bank_name,
            account_number: selectedBank.account_number,
            iffc_code: selectedBank.iffc_code,
          });
          console.log("dataaa",formDataUpdate);
    } else {
        console.log("Recipts not found");
    }
   
};

const UpdateBank = async (event) => {
    event.preventDefault();
    setLoading_update(true);
    try {
        const response = await dispatch(EditBankAPI(formDataUpdate, selectedCompanyId));
        setLoading_update(false);
        if(response?.type == "ADD_DATA_SUCCESS_EDIT_BANK"){
          refEditClose.current.click();
            await Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Company updated successfully.',
                customClass: {
                    container: 'my-custom-swal', // Add your custom class here
                },
            });
           dispatch(fatchBankAPI());
        }
          else {
          // Show error SweetAlert
            await Swal.fire({
                icon: 'error',
                title: 'Some Error',
                text: 'Failed to update the Company. Please try again.',
            });
        }
          
    } catch (error) {
      console.error('Error updating Company:', error);
    }
  };

const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeUpdate = (event) => {
    const { name, value } = event.target;
    setFormDataUpdate(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  return (
    <>
      <Card>
              <div className='text-center'>
                {loading && <Loader />}
              </div>
              {!loading && (
              <div class="row p-4 pt-3">
              {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                [...FetchBankReducers.data].reverse().map(item => (
                  <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <h4>Add Bank Details</h4>
                      <hr class="w-10"/>
                      <div class="d-flex justify-content-between mt-3 md-block">
                          <p class="text-dark profile_name">Account Holder Name :</p>
                          <h6 class="text-dark profile_name_data">{item.account_holder_name}</h6>
                      </div>
                      <div class="d-flex justify-content-between md-block">
                          <p class="text-dark profile_name">Bank Name :</p>
                          <h6 class="text-dark profile_name_data">{item.bank_name}</h6>
                      </div>
                      <div class="d-flex justify-content-between md-block">
                          <p class="text-dark profile_name">Account Number:</p>
                          <h6 class="text-dark profile_name_data">{item.account_number}</h6>
                      </div>
                      <div class="d-flex justify-content-between md-block">
                          <p class="text-dark profile_name">IFFC Code :</p>
                          <h6 class="text-dark profile_name_data">{item.iffc_code}</h6>
                      </div>
                      <button ref={refEditBank} class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#editBankModel" aria-controls="staticBackdrop" onClick={() => UpdateBankClick(item.id)}>
                          Edit Details+
                          </button>
                  </div>
                  ))
                  ) : (
                      <div className='text-center not_data mt-3'>
                          <i class="fa-solid fa-triangle-exclamation"></i>
                          <p>Add Your Bank Details</p>
                          <button ref={ref} class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop2" aria-controls="staticBackdrop">
                            Add Bank+
                          </button>
                      </div>
                  )} 
              </div>
              )}
              {/* Add Bank Profile Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop2" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Invoice</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div className='text-center'>
                        {loading_create && <Loader />}
                    </div>
                <Form onSubmit={handleSubmit}>
                    {!loading_create && (
                        <Row className="mb-3">
                            <h5 className='mb-3'>Bank Details</h5>
                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                                <Form.Label>Account holder's Name</Form.Label>
                                <Form.Control type="text" value={formData.account_holder_name} name="account_holder_name" onChange={handleChange} placeholder="Account holder's Name" required/>
                                {error?.account_holder_name && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.account_holder_name && error.account_holder_name}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control type="text" name="bank_name" onChange={handleChange} placeholder="Bank Name.." value={formData.bank_name} required/>
                                {error?.bank_name && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.bank_name && error.bank_name}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control type="number" min={0} name="account_number" onChange={handleChange} placeholder="Company Name.." value={formData.account_number} required/>
                                {error?.account_number && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.account_number && error.account_number}
                                    </span>
                                )}
                            </Form.Group>

                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                <Form.Label>IFFC Code</Form.Label>
                                <Form.Control type="text" name="iffc_code" onChange={handleChange} placeholder="IFFC Code.." value={formData.iffc_code} required/>
                            </Form.Group>

                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                <Button variant="primary" type="submit" className='mt-3'>
                                    Submit
                                </Button>
                                <Button variant="secondary" type="reset" onClick={resetForm} className='mx-2 mt-3'>
                                    Reset
                                </Button>
                            </Form.Group>
                        </Row>
                    )}
                </Form>
                </div>
            </div>
            {/* Add Bank Profile End */}

            {/* Edit Bank Profile Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="editBankModel" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Bank Details</h5>
                    <button type="button" ref={refEditClose} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                <Form onSubmit={UpdateBank}>
                    <Row className="mb-3">
                        <h5 className='mb-3'>Bank Details</h5>
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                            <Form.Label>Account holder's Name</Form.Label>
                            <Form.Control type="text" value={formDataUpdate.account_holder_name} name="account_holder_name" onChange={handleChangeUpdate} placeholder="Account holder's Name" required/>
                            {error_edit?.account_holder_name && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {error_edit.account_holder_name && error_edit.account_holder_name}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control type="text" name="bank_name" onChange={handleChangeUpdate} placeholder="Bank Name.." value={formDataUpdate.bank_name} required/>
                            {error_edit?.bank_name && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {error_edit.bank_name && error_edit.bank_name}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control type="number" min={0} name="account_number" onChange={handleChangeUpdate} placeholder="Company Name.." value={formDataUpdate.account_number} required/>
                            {error_edit?.account_number && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {error_edit.account_number && error_edit.account_number}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                            <Form.Label>IFFC Code</Form.Label>
                            <Form.Control type="text" name="iffc_code" onChange={handleChangeUpdate} placeholder="IFFC Code.." value={formDataUpdate.iffc_code} required/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                            <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                <span className='mx-2'>Update</span>
                                {loading_update && <span className='spin-sm'><Loader /></span>}
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
                </div>
            </div>
            {/* Add Bank Profile End */}
      </Card>
            
    </>
  )
}

export default CompanyBank
