import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
import SignatureCanvas from 'react-signature-canvas';
import SignatureCanvasEdit from 'react-signature-canvas';

import { signUpload,fatchSignAPI,EditSignAPI} from '../../redux/actionCreation';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';


  function CompanySign() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    const [loading_create, setLoading_create] = useState(false);
    const [newSignatureData, setNewSignatureData] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      setShow(true);
    }

      const signatureCanvas = useRef(null);
      const signatures = useSelector((state) => state.fetchReducers);

      const handleSave = async () => {
        setLoading_create(true);
        if (signatureCanvas.current.isEmpty()) {
          await Swal.fire({
            icon: 'error',
            title: 'Signature Empty',
            text: 'Please add a signature before saving.',
          });
          setLoading_create(false);
          return;
        }
        const signatureData = signatureCanvas.current.toDataURL();
          await dispatch(signUpload(signatureData));
          const res = await dispatch(fatchSignAPI());
          setLoading_create(false);
      };
      
        const handleEdit = async () => {
          setLoading_update(true);
            if (signatureCanvas.current.isEmpty()) {
              await Swal.fire({
                icon: 'error',
                title: 'Signature Empty',
                text: 'Please add a signature before saving.',
              });
              setLoading_update(false);
              return;
            }
            const editedSignatureData = signatureCanvas.current.toDataURL();
            
            await dispatch(EditSignAPI(editedSignatureData));
            const res = await dispatch(fatchSignAPI());
            setLoading_update(false);
            handleClose(); // Close modal after editing
        };
        
        const handleClear = () => {
          signatureCanvas.current.clear();
        };

        useEffect(() => {
          const fetchData = async () => {
              try {
                  const res = await dispatch(fatchSignAPI());
                  // Process the result if needed
              } catch (error) {
                  console.error('Error fetching data:', error);
              }
          };
          fetchData(); // Call the async function
      }, []);
      

    return (
      <>
        <Card>
              <Card.Body>
                  <Row className="mb-3">
                  {!(signatures && Array.isArray(signatures.data) && signatures.data.length > 0) && (
                      <Col xs="12" sm="12" md="8" lg="8" xl="8" xxl="8" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                        <h5 className='mb-3'>Please Give Signature in space provided</h5>
                        <SignatureCanvas
                          penColor="blue"
                          canvasProps={{ width: 300, height: 100, className: 'sigCanvas' }}
                          ref={signatureCanvas}
                        /><br/>
                        <div className='d-flex align-items-center justify-content-left'>
                          <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center' onClick={handleSave}>
                            <span className='mx-2'>Save Signature</span>
                            {loading_create && <span className='spin-sm'><Loader /></span>}
                          </Button>
                          <Button onClick={handleClear} className='mx-2 btn btn-secondary'>Clear Signature</Button>
                        </div>
                      </Col>
                    )}
                    {signatures && Array.isArray(signatures.data) && signatures.data.length > 0 ? (
                      <Col xs="12" sm="12" md="8" lg="8" xl="8" xxl="8" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                          <h5 className='mb-3'>Your Signature</h5>
                            <Card className='sign-view'>
                                {[...signatures.data].map((signature, index) => (
                                  <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} />
                                ))}
                            </Card>
                            <button onClick={handleShow} className='btn btn-primary mt-3'>Edit Signature</button>
                          </Col>
                        ) : (
                          <p></p>
                        )}
                  </Row>
              </Card.Body>
        </Card>

        {/* Sign Update Model */}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className='model-index'
          >
          <Modal.Header closeButton>
            <Modal.Title>Edit Signature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <SignatureCanvasEdit
                penColor="blue"
                canvasProps={{ width: 300, height: 100, className: 'sigCanvas2' }}
                ref={signatureCanvas}
              />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            
            <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center' onClick={handleEdit}>
                <span className='mx-2'>Save Signature</span>
                {loading_update && <span className='spin-sm'><Loader /></span>}
            </Button>
            <Button variant="secondary" onClick={handleClear}>Clear Signature</Button>
          </Modal.Footer>
      </Modal>
      {/* Sign Update Model */}
      </>
    )
}

export default CompanySign
