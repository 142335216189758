import React from 'react'

function Notfound() {
  return (
    <div>
      <h1 className='text-center'>404 Not found</h1>
    </div>
  )
}

export default Notfound
