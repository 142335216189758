const initialState = "";

const navbarReducers = (state=initialState, action) => {
    switch(action.type){
        case 'sidebar_click':
        return(
            (!state)
        )

        default: return state
    }
}

export default navbarReducers;