import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import MobileNavigation from './MobileNavigation';
import { useNavigate } from 'react-router-dom';

function Aboutus() {
  const sidebarState = useSelector(state => state.sidebarReducres);
  const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                            <h4>About Us</h4>
                            <p>Packers Billing software simplifies your billing process with its intuitive interface. Create professional invoices, quotations, and receipts effortlessly. Enjoy secure data handling, automated GST calculation, and mobile/desktop accessibility. Take advantage of our user-friendly features to streamline your billing tasks efficiently.</p>

                            <h4>Why Choice Us?</h4>
                            <p>When it comes to sending quotations to your customers, you might find yourself facing some challenges. Whether it's writing them on paper or relying on computer templates, there's always the risk of not having backups or access to a computer when needed. Introducing Packers Billing the solution to your problem.</p>
                            <p>With this Software, generating quotations bills and recipt for your clients becomes a breeze, ensuring convenience and efficiency every step of the way.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default Aboutus
