import React, { useEffect } from 'react';
import Logo from '../../images/logo.png'
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {useDispatch, useSelector } from 'react-redux';
import { navbarAction, fatchUserAPI} from "../../redux/actionCreation";
import { Link,to, useNavigate } from 'react-router-dom';

function AdminHeader(props) {
    useEffect(()=>{
        if(!localStorage.getItem('admin-tokan')){
            navigate('/admin-greyline');
        }
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const username = fetchUserReducres.data ? fetchUserReducres.data.username : null;

    // Fetch Quotations Data
    useEffect(() => {
        const fetchDataUser = async () => {
          try {
            const res = await dispatch(fatchUserAPI());
          } catch (error) {
            console.error('Error fetching quotations data:', error);
          }
        };
        fetchDataUser();
      }, []);

      const logoutFun = () =>{
        localStorage.removeItem('admin-tokan');
      };
      
  return (
    <>
      <Navbar className="header-section">
          <Navbar.Brand className={`${props.addClass}`}>
            <Link to="/">
              <img src={Logo ? `${Logo}` : `${Logo}`} className="logo-size" alt="logo"/>
            </Link>
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link className="">
                {/* dd */}
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <div class="header-profile dropdown">
                <button className="dropdown-toggle btn btn-primary username" type="button"  data-bs-toggle="dropdown" aria-expanded="false">
                 Greyline
                </button>
              
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                    <Link className="dropdown-item" to="/admin-greyline" onClick={logoutFun}>
                        <i className="fa fa-sign-out"></i> Logout
                    </Link>
                </li>
              </ul>
            </div>
          </Nav>
      </Navbar>
    </>
  )
}

export default AdminHeader
