import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector } from 'react-redux';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';
import CompanyProfile from './UpdateCompany/CompanyProfile';
import CompanyBank from './UpdateCompany/CompanyBank';
import CompanySign from './UpdateCompany/CompanySign';
import { useNavigate } from 'react-router-dom';
import CompanyTearms from './UpdateCompany/CompanyTearms';

function UpdateCompany() {
  const sidebarState = useSelector(state => state.sidebarReducres);
  const navigate = useNavigate();
  useEffect(()=>{
    if(!localStorage.getItem('tokan')){
        navigate('/login');
    }
}, []);
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                            <h4>My Company Details</h4>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mt-3'>
                            <Tabs
                                defaultActiveKey="profile"
                                id="uncontrolled-tab-example"
                                className="mb-3 updatecompanysection"
                                >
                                <Tab eventKey="profile" title="Profile" className='updatecompany'>
                                    <CompanyProfile/>
                                </Tab>
                                <Tab eventKey="bank" title="Bank Details" className='updatecompany'>
                                    <CompanyBank/>
                                </Tab>
                                <Tab eventKey="sign" title="Signature" className='updatecompany'>
                                    <CompanySign/>
                                </Tab>
                                <Tab eventKey="tearms" title="Tearms" className='updatecompany'>
                                    <CompanyTearms/>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default UpdateCompany
