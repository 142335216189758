import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { reciptAPI } from '../../redux/actionCreation';
import MobileNavigation from '../MobileNavigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../Loader';

function CreateReceipts() {
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const [loading, setLoading] = useState(false);
    const sidebarState = useSelector(state => state.sidebarReducres);
    const error2 = useSelector(state => state.reciptsReducers.error && state.reciptsReducers.error.create_recipts_errors) ?? null;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ 
        name: '',
        phone_number: '',
        recipts_date: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        payment_type: 'cash', // Default value
        total_amount: '',
        received_amount: '',
      });

      const resetForm = async () => {
        const confirmation = await Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'This will reset the form. Are you sure you want to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });
        if (confirmation.isConfirmed) {
            setFormData({
                receipt_number: '',
                name: '',
                phone_number: '',
                recipts_date: '',
                service_name: '',
                from_city: '',
                to_city: '',
                shift_start_date: '',
                shift_end_date: '',
                payment_type: '', // Default value
                total_amount: '',
                received_amount: '',
            });
        }
      };
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const handleReceiptDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
    
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            recipts_date: modifiedDate,
        }));
    };

      const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };
    
      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
          const res = await dispatch(reciptAPI(formData));
          setLoading(false);
          if(res?.type == "ADD_DATA_SUCCESS_RECIPTS"){
            navigate("/recipteone");
          }
          else {
            // Show error SweetAlert
              await Swal.fire({
                  icon: 'error',
                  title: 'Some Error',
                  text: 'Please check your form. Some fields are not filled in properly.',
              });
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

    
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mb-2'>
                            <h5>Create Recipt</h5>
                        </Col>
                    </Row>

                    <Card className='quotaion-create'>
                        <Card.Body className='p-3'>
                            <Form onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <h6 className='mb-0'>General Details:</h6>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                         <hr className='w-10'/>
                                    </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                        {error2?.cust_name && (
                                        <span className="alert alert-danger  mb-1 d-block mt-1 pt-1 pb-1">
                                        {error2.cust_name && error2.cust_name}
                                        </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="number" min={0} name="phone_number" onChange={handleChange} placeholder="Customer Phone.." value={formData.phone_number}  required/>

                                        {error2?.phone_greater && (
                                        <span className="alert alert-danger d-block  mb-1 mt-1 pt-1 pb-1">
                                        {error2.phone_greater && error2.phone_greater}
                                        </span>
                                        )}

                                        {error2?.phone_less && (
                                        <span className="alert alert-danger d-block  mb-1 mt-1 pt-1 pb-1">
                                        {error2.phone_less && error2.phone_less}
                                        </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Recipt Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.recipts_date}
                                                    onChange={handleReceiptDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Receipt Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control type="text" placeholder="Service Name.." onChange={handleChange}
                                            value={formData.service_name} name="service_name" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>From City/Area</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} placeholder="From.." name="from_city" value={formData.from_city}  required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="To.." value={formData.to_city} name="to_city" required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <hr className='w-10'/>
                                    </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error2?.shift_end_date && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                            {error2.shift_end_date && error2.shift_end_date}
                                            </span>
                                            )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Payment Type</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="payment_type" value={formData.payment_type}>
                                            <option value="cash">Cash</option>
                                            <option value="online">Online</option>
                                            <option value="Cheque">Cheque</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control type="number" min={0} placeholder="Total Amount.." onChange={handleChange} value={formData.total_amount} name="total_amount" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Received Amount</Form.Label>
                                        <Form.Control type="number" min={0} onChange={handleChange} placeholder="Received Amount" name="received_amount" value={formData.received_amount} required/>
                                        {error2?.received_amount && (
                                        <span className="alert alert-danger d-block  mb-1 mt-1 pt-1 pb-1">
                                        {error2.received_amount && error2.received_amount}
                                        </span>
                                        )}
                                    </Form.Group>
                                </Row>

                                <div className='d-flex align-items-center'>
                                    <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                        <span className='mx-2'>Submit</span>
                                        {loading && <span className='spin-sm'><Loader /></span>}
                                    </Button>
                                    <Button variant="secondary" type="reset" onClick={resetForm} className='mx-2'>
                                        Reset
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default CreateReceipts
