import React, { useEffect } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';

function OtherService() {
    const sidebarState = useSelector(state => state.sidebarReducres);
    const navigate = useNavigate();
      useEffect(()=>{
          if(!localStorage.getItem('tokan')){
              navigate('/login');
          }
      }, []);
  return (
    <>
      <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mb-1'>
                            <h4>Packers and Movers Website</h4>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>STARTER Package</h5>
                                        <h4 className='sub-plan'>3500 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li><i className='text-success fa fa-check'></i> 5 Page Static</li>
                                        <li><i className='text-success fa fa-check'></i> Normal Design</li>
                                        <li><i className='text-danger fa fa-xmark'></i> 1 Domain <span className='text-danger'>1 Year</span></li>
                                        <li><i className='text-danger fa fa-xmark'></i> 1 Hosting <span className='text-danger'>1 Year</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Inquiry Form </li>
                                        <li><i className='text-success fa fa-check'></i> 1 WhatsApp Chat</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Live Chat</li>
                                        <li><i className='text-success fa fa-check'></i> Social Share Links Button</li>
                                        <li><i className='text-success fa fa-check'></i> Mobile Responsive</li>
                                        <li><i className='text-success fa fa-check'></i> Google Map</li>
                                        <li><i className='text-danger fa fa-xmark'></i> Packers Billing Software</li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>BASIC Package</h5>
                                        <h4 className='sub-plan'>5500 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li><i className='text-success fa fa-check'></i> 11 Page Static</li>
                                        <li><i className='text-success fa fa-check'></i> Professional Design</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Domain (.co.in) <span className='text-success'>(1 Year)</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Hosting <span className='text-success'>(1 Year)</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Inquiry Form </li>
                                        <li><i className='text-success fa fa-check'></i> 1 WhatsApp Chat</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Live Chat</li>
                                        <li><i className='text-success fa fa-check'></i> Social Share Links Button</li>
                                        <li><i className='text-success fa fa-check'></i> Mobile Responsive</li>
                                        <li><i className='text-success fa fa-check'></i> Google Map</li>
                                        <li><i className='text-danger fa fa-xmark'></i> Packers Billing Software</li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>PREMIUM Package</h5>
                                        <h4 className='sub-plan'>6500 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li><i className='text-success fa fa-check'></i> 15 Page Static</li>
                                        <li><i className='text-success fa fa-check'></i> Professional Design</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Domain (.co.in) <span className='text-success'>(1 Year)</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Hosting <span className='text-success'>(1 Year)</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Inquiry Form </li>
                                        <li><i className='text-success fa fa-check'></i> 1 WhatsApp Chat</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Live Chat</li>
                                        <li><i className='text-success fa fa-check'></i> Social Share Links Button</li>
                                        <li><i className='text-success fa fa-check'></i> Mobile Responsive</li>
                                        <li><i className='text-success fa fa-check'></i> Google Map</li>
                                        <li><i className='text-success fa fa-check'></i> Packers Billing Software <span className='text-success'>(1 Year)</span></li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>ULTIMATE Package</h5>
                                        <h4 className='sub-plan'>9999 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li><i className='text-success fa fa-check'></i> 20 Page Static</li>
                                        <li><i className='text-success fa fa-check'></i> Full Professional</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Domain (.in) <span className='text-success'>(1 Year)</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Hosting <span className='text-success'>(1 Year)</span></li>
                                        <li><i className='text-success fa fa-check'></i> 1 Inquiry Form </li>
                                        <li><i className='text-success fa fa-check'></i> 1 WhatsApp Chat</li>
                                        <li><i className='text-success fa fa-check'></i> 1 Live Chat</li>
                                        <li><i className='text-success fa fa-check'></i> Social Share Links Button</li>
                                        <li><i className='text-success fa fa-check'></i> Mobile Responsive</li>
                                        <li><i className='text-success fa fa-check'></i> Google Map</li>
                                        <li><i className='text-success fa fa-check'></i> Google Console Account</li>
                                        <li><i className='text-success fa fa-check'></i> Packers Billing Software <span className='text-success'>(1 Year)</span></li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default OtherService
