export const navbarAction = ()=>{
    return{
        type:"sidebar_click"
    }
}

// Company Register API Call POST Method /api/auth
export const registerAPI = (formData) => {
    return async dispatch => {
        const response = await fetch('https://app.packersbilling.in/api/auth',{
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json"
          }
        });
        
        let data = await response.json();

        console.log('res',data, response.status)
        if(response.status === 400){
          dispatch({
            type: 'ADD_DATA_FAILURE',
            payload: data
          });
        }else if (response.status === 200){
          return dispatch({
            type: 'ADD_DATA_SUCCESS',
            payload: data,
          });
        }
  };
};

// Company Login API Call POST Method /api/login
export const loginAPI = (formData) => {
  return async dispatch => {
      const response = await fetch('https://app.packersbilling.in/api/login',{
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      let data = await response.json();
      
      if(response.status === 400){
        dispatch({
          type: 'ADD_DATA_FAILURE',
          payload: data
        });
      }else if (response.status === 200){
        localStorage.setItem('tokan',data.authToken);
        return dispatch({
          type: 'ADD_DATA_SUCCESS',
          payload: data,
        });
      }
  };
};

// Company Bill API Call POST Method /api/bill-create
export const billAPI = (formData) =>{
  const tok =  localStorage.getItem('tokan');
     // Format the quotation_date before sending it in the API request
     const updatedFormData = { ...formData };
     const dateProperties = ['bill_date', 'shift_start_date', 'shift_end_date'];
  
     dateProperties.forEach(property => {
       // Check if the property exists in formData and is an instance of Date
       if (updatedFormData[property] instanceof Date) {
         // Format the date property before updating it
         updatedFormData[property].setHours(0, 0, 0, 0);
         const day = String(updatedFormData[property].getDate()).padStart(2, '0');
         const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
         const year = String(updatedFormData[property].getFullYear());
         const formattedDate = `${day}-${month}-${year}`;
   
         // Update the date property in updatedFormData with the formatted date
         updatedFormData[property] = formattedDate;
       }
     });

  return async dispatch =>{
    const response = await fetch('https://app.packersbilling.in/api/bill-create',{
      method : "POST",
      body: JSON.stringify(updatedFormData),
      headers: {
        "Content-Type": "application/json",
        "auth-tokan":tok
      }
    });
    let data = await response.json();
    if(response.status === 400){
      dispatch({
        type: 'ADD_DATA_FAILURE',
        payload: data
      });
    }else if (response.status === 200){
      return dispatch({
        type: 'ADD_DATA_SUCCESS',
        payload: data,
      });
    }
  }
}

 // Company Bill API Call POST Method /api/fatch-bill
 export const fatchBillAPI = () =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch('https://app.packersbilling.in/api/fatch-bill',{
       method : "GET",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     if(response.status === 400){
       dispatch({
         type: 'ADD_DATA_FAILURE_BILL',
         payload: data
       });
     }else if (response.status === 200){
       return dispatch({
         type: 'ADD_DATA_SUCCESS_BILL',
         payload: data,
       });
     }
   }
 }

  //  Company Recipts Featch API Call Get Method /api/fatch-bill/${billId}
  export const fatchBillIDAPI = (billId) => {
    const tok = localStorage.getItem('tokan');
    return async dispatch => {
      try {
        const response = await fetch(`https://app.packersbilling.in/api/fatch-bill/${billId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'auth-tokan': tok,
          },
        });
  
        let data = await response.json();
        console.log('fatch ID bill api data', data);
        if (response.status === 400) {
          return dispatch({
            type: 'ADD_DATA_FAILURE_BILL_ID',
            payload: data,
          });
        } else if (response.status === 200) {
          return dispatch({
            type: 'ADD_DATA_SUCCESS_BILL_ID',
            payload: data,
          });
        }
      } catch (error) {
        console.error('Error fetching quotation:', error);
        return dispatch({
          type: 'ADD_DATA_FAILURE_RECIPTS',
          payload: { error: 'An error occurred while fetching quotation.' },
        });
      }
    };
  };

 // Company Bill API Call DELETE Method /api/delete-bill/${billId}
export const DeleteBillAPI = (billId) =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/delete-bill/${billId}`, {
       method : "DELETE",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     if(response.status === 200){
       dispatch({
         type: 'ADD_DATA_SUCCESS_DELETE_BILL',
         payload: data
       });
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_DELETE_BILL',
         payload: data,
       });
     }
   }
 }

  // Company Bill API Call PUT Method /api/update-bill/${billId}
export const EditBillAPI = (formData, billId) =>{
  const tok =  localStorage.getItem('tokan');
   // List of date properties to be updated
   const updatedFormData = { ...formData };
   const dateProperties = ['bill_date', 'shift_start_date', 'shift_end_date'];

   dateProperties.forEach(property => {
     // Check if the property exists in formData and is an instance of Date
     if (updatedFormData[property] instanceof Date) {
       // Format the date property before updating it
       updatedFormData[property].setHours(0, 0, 0, 0);
       const day = String(updatedFormData[property].getDate()).padStart(2, '0');
       const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
       const year = String(updatedFormData[property].getFullYear());
       const formattedDate = `${day}-${month}-${year}`;
 
       // Update the date property in updatedFormData with the formatted date
       updatedFormData[property] = formattedDate;
     }
   });

   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/update-bill/${billId}`, {
       method : "PUT",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       },
       body: JSON.stringify(updatedFormData),
     });
     const data = await response.json();
     if(response.status === 200){
      return dispatch({
         type: 'ADD_DATA_SUCCESS_EDIT_BILL',
         payload: data,
       });
       return data;
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_EDIT_BILL',
         payload: data,
       });
     }
   }
 }

// Company Quotation API Call POST Method /api/quotation-create
  export const quotationAPI = (formData) =>{
    const tok =  localStorage.getItem('tokan');
    const updatedFormData = { ...formData };

    // List of date properties to be updated
    const dateProperties = ['quotation_date', 'shift_start_date', 'shift_end_date'];
  
    dateProperties.forEach(property => {
      // Check if the property exists in formData and is an instance of Date
      if (updatedFormData[property] instanceof Date) {
        // Format the date property before updating it
        updatedFormData[property].setHours(0, 0, 0, 0);
        const day = String(updatedFormData[property].getDate()).padStart(2, '0');
        const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
        const year = String(updatedFormData[property].getFullYear());
        const formattedDate = `${day}-${month}-${year}`;
  
        // Update the date property in updatedFormData with the formatted date
        updatedFormData[property] = formattedDate;
      }
    });

    return async dispatch =>{
      const response = await fetch('https://app.packersbilling.in/api/quotation-create',{
        method : "POST",
        body: JSON.stringify(updatedFormData),
        headers: {
          "Content-Type": "application/json",
          "auth-tokan":tok
        }
      });
    let data = await response.json();
    if(response.status === 400){
      dispatch({
        type: 'ADD_DATA_FAILURE',
        payload: data
      });
    }else if (response.status === 200){
      return dispatch({
        type: 'ADD_DATA_SUCCESS',
        payload: data,
      });
    }
  }
}

// Company Quotation API Call POST Method /api/fatch-quotation
  export const fatchQuotationAPI = () =>{
    const tok =  localStorage.getItem('tokan');
     return async dispatch =>{
       const response = await fetch('https://app.packersbilling.in/api/fatch-quotation',{
         method : "GET",
         headers: {
           "Content-Type": "application/json",
           "auth-tokan":tok
         }
       });
       let data = await response.json();
       if(response.status === 400){
         dispatch({
           type: 'ADD_DATA_FAILURE_QUOTATION',
           payload: data
         });
       }else if (response.status === 200){
         return dispatch({
           type: 'ADD_DATA_SUCCESS_QUOTATION',
           payload: data,
         });
       }
     }
   }

   //  Company Quotation Featch API Call Get Method /api/fatch-quotation/${quotationId}
 export const fatchQuotationIDAPI = (quotationId) => {
  const tok = localStorage.getItem('tokan');
  return async dispatch => {
    try {
      const response = await fetch(`https://app.packersbilling.in/api/fatch-quotation/${quotationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-tokan': tok,
        },
      });

      let data = await response.json();
      console.log('fatch ID quotation api data', data);
      if (response.status === 400) {
        return dispatch({
          type: 'ADD_DATA_FAILURE_QUOTATION_ID',
          payload: data,
        });
      } else if (response.status === 200) {
        return dispatch({
          type: 'ADD_DATA_SUCCESS_QUOTATION_ID',
          payload: data,
        });
      }
    } catch (error) {
      console.error('Error fetching quotation:', error);
      return dispatch({
        type: 'ADD_DATA_FAILURE_RECIPTS',
        payload: { error: 'An error occurred while fetching quotation.' },
      });
    }
  };
};


   // Company Bill API Call DELETE Method /api/delete-quotation/${quotationId}
export const DeleteQuotationAPI = (quotationId) =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/delete-quotation/${quotationId}`, {
       method : "DELETE",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     if(response.status === 200){
       dispatch({
         type: 'ADD_DATA_SUCCESS_DELETE_QUOTATION',
         payload: data
       });
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_DELETE_QUOTATION',
         payload: data,
       });
     }
   }
 }

 // Company Quotation API Call PUT Method /api/update-quotation/${quotationId}
export const EditQuotationAPI = (formData, quotationId) =>{
  const tok =  localStorage.getItem('tokan');
  const updatedFormData = { ...formData };

  // List of date properties to be updated
  const dateProperties = ['quotation_date', 'shift_start_date', 'shift_end_date'];

  dateProperties.forEach(property => {
    // Check if the property exists in formData and is an instance of Date
    if (updatedFormData[property] instanceof Date) {
      // Format the date property before updating it
      updatedFormData[property].setHours(0, 0, 0, 0);
      const day = String(updatedFormData[property].getDate()).padStart(2, '0');
      const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
      const year = String(updatedFormData[property].getFullYear());
      const formattedDate = `${day}-${month}-${year}`;

      // Update the date property in updatedFormData with the formatted date
      updatedFormData[property] = formattedDate;
    }
  });

   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/update-quotation/${quotationId}`, {
       method : "PUT",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       },
       body: JSON.stringify(updatedFormData),
     });
     const data = await response.json();
     if(response.status === 200){
      return dispatch({
         type: 'ADD_DATA_SUCCESS_EDIT_QUOTATION',
         payload: data,
       });
       return data;
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_EDIT_QUOTATION',
         payload: data,
       });
     }
   }
 }


// Company Recipts API Call POST Method /api/recipts-create
export const reciptAPI = (formData) => {
  const tok = localStorage.getItem('tokan');
  const updatedFormData = { ...formData };
  // List of date properties to be updated
  const dateProperties = ['recipts_date', 'shift_start_date', 'shift_end_date'];
  dateProperties.forEach(property => {
    // Check if the property exists in formData and is an instance of Date
    if (updatedFormData[property] instanceof Date) {
      // Format the date property before updating it
      updatedFormData[property].setHours(0, 0, 0, 0);
      const day = String(updatedFormData[property].getDate()).padStart(2, '0');
      const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
      const year = String(updatedFormData[property].getFullYear());
      const formattedDate = `${day}-${month}-${year}`;
      // Update the date property in updatedFormData with the formatted date
      updatedFormData[property] = formattedDate;
    }
  });
  return async (dispatch) => {
    const response = await fetch('https://app.packersbilling.in/api/recipts-create', {
      method: 'POST',
      body: JSON.stringify(updatedFormData),
      headers: {
        'Content-Type': 'application/json',
        'auth-tokan': tok,
      },
    });

    let data = await response.json();
    console.log('Create recipt api data', data);

    if (response.status === 400) {
      return dispatch({
        type: 'ADD_DATA_FAILURE_RECIPTS',
        payload: data,
      });
    } else if (response.status === 200) {
      return dispatch({
        type: 'ADD_DATA_SUCCESS_RECIPTS',
        payload: data,
      });
    }
  };
};

 // Company Recipts API Call POST Method /api/fatch-recipts
 export const fatchReciptAPI = () => {
  const tok = localStorage.getItem('tokan');
  return async dispatch => {
    try {
      const response = await fetch(`https://app.packersbilling.in/api/fatch-recipts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-tokan': tok,
        },
      });

      let data = await response.json();
      console.log('fatch recipt api data', data);
      if (response.status === 400) {
        return dispatch({
          type: 'ADD_DATA_FAILURE_RECIPTS',
          payload: data,
        });
      } else if (response.status === 200) {
        return dispatch({
          type: 'ADD_DATA_SUCCESS_RECIPTS',
          payload: data,
        });
      }
    } catch (error) {
      console.error('Error fetching receipts:', error);
      dispatch({
        type: 'ADD_DATA_FAILURE_RECIPTS',
        payload: { error: 'An error occurred while fetching receipts.' },
      });
    }
  };
};

//  Company Recipts Featch API Call Get Method /api/fatch-recipts/${reciptId}
 export const fatchReciptIDAPI = (reciptId) => {
  const tok = localStorage.getItem('tokan');
  return async dispatch => {
    try {
      const response = await fetch(`https://app.packersbilling.in/api/fatch-recipts/${reciptId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-tokan': tok,
        },
      });

      let data = await response.json();
      console.log('fatch ID recipt api data', data);
      if (response.status === 400) {
        return dispatch({
          type: 'ADD_DATA_FAILURE_RECIPTS_ID',
          payload: data,
        });
      } else if (response.status === 200) {
        return dispatch({
          type: 'ADD_DATA_SUCCESS_RECIPTS_ID',
          payload: data,
        });
      }
    } catch (error) {
      console.error('Error fetching receipts:', error);
      return dispatch({
        type: 'ADD_DATA_FAILURE_RECIPTS',
        payload: { error: 'An error occurred while fetching receipts.' },
      });
    }
  };
};

    // Company Recipts API Call DELETE Method /api/delete-recipts/${reciptsId}
export const DeleteReciptsAPI = (reciptsId) =>{
  const tok =  localStorage.getItem('tokan');
  
   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/delete-recipts/${reciptsId}`, {
       method : "DELETE",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     if(response.status === 200){
       dispatch({
         type: 'ADD_DATA_SUCCESS_DELETE_RECIPTS',
         payload: data
       });
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_DELETE_RECIPTS',
         payload: data,
       });
     }
   }
 }

   // Company Receipt API Call PUT Method /api/update-recipts/${reciptId}
   export const EditReciptAPI = (formData, reciptId) => {
    const tok = localStorage.getItem('tokan');
    const updatedFormData = { ...formData };

    // List of date properties to be updated
  const dateProperties = ['recipts_date', 'shift_start_date', 'shift_end_date'];

  dateProperties.forEach(property => {
    // Check if the property exists in formData and is an instance of Date
    if (updatedFormData[property] instanceof Date) {
      // Format the date property before updating it
      updatedFormData[property].setHours(0, 0, 0, 0);
      const day = String(updatedFormData[property].getDate()).padStart(2, '0');
      const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
      const year = String(updatedFormData[property].getFullYear());
      const formattedDate = `${day}-${month}-${year}`;

      // Update the date property in updatedFormData with the formatted date
      updatedFormData[property] = formattedDate;
    }
  });

  console.log('Updated Form Data:', updatedFormData); // Log the updated form data
  
    return async (dispatch) => {
      const response = await fetch(`https://app.packersbilling.in/api/update-recipts/${reciptId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'auth-tokan': tok,
        },
        body: JSON.stringify(updatedFormData),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
       return dispatch({
          type: 'ADD_DATA_SUCCESS_EDIT_RECEIPT',
          payload: data,
        });
      } else if (response.status === 400) {
        return dispatch({
          type: 'ADD_DATA_FAILURE_EDIT_RECEIPT',
          payload: data,
        });
      }
    };
  };
  
 // Company  API Call POST Method /api/getuser
  export const fatchUserAPI = () =>{
    const tok =  localStorage.getItem('tokan');
     return async dispatch =>{
       const response = await fetch('https://app.packersbilling.in/api/getuser',{
         method : "POST",
         headers: {
           "Content-Type": "application/json",
           "auth-tokan":tok
         }
       });
       let data = await response.json();
       if(response.status === 400){
        return dispatch({
           type: 'ADD_DATA_FAILURE_USER',
           payload: data
         });
       }else if (response.status === 200){
         return dispatch({
           type: 'ADD_DATA_SUCCESS_USER',
           payload: data,
         });
       }
     }
   }

// Company Receipt API Call PUT Method /api/update-user/${userId}
export const editUserAPI = (formData, userId) =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/update-user/${userId}`, {
       method : "PUT",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       },
       body: JSON.stringify(formData),
     });
     const data = await response.json();
     if(response.status === 200){
      return dispatch({
         type: 'ADD_DATA_SUCCESS_EDIT_USER',
         payload: data,
       });
       return data;
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_EDIT_USER',
         payload: data,
       });
     }
   }
 }

 // Company Bank Add API Call POST Method /api/bank-details
export const bankAddAPI = (formData) =>{
  const tok =  localStorage.getItem('tokan');
  return async dispatch =>{
    const response = await fetch('https://app.packersbilling.in/api/bank-details',{
      method : "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "auth-tokan":tok
      }
    });
    let data = await response.json();
    if(response.status === 400){
      dispatch({
        type: 'ADD_DATA_BANK_FAILURE',
        payload: data
      });
    }else if (response.status === 200){
      return dispatch({
        type: 'ADD_DATA_BANK_SUCCESS',
        payload: data,
      });
    }
  }
}

 // Company  API Call POST Method /api/getuser
 export const fatchBankAPI = () =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch('https://app.packersbilling.in/api/fetch-bank',{
       method : "GET",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     console.log("Action API",data);
     if(response.status === 400){
      return dispatch({
         type: 'FETCH_DATA_FAILURE_BANK',
         payload: data
       });
     }else if (response.status === 200){
       return dispatch({
         type: 'FETCH_DATA_SUCCESS_BANK',
         payload: data,
       });
     }
   }
 }

   // Company Receipt API Call PUT Method /api/update-bank/${bankId}
   export const EditBankAPI = (formDataUpdate, bankId) =>{
    const tok =  localStorage.getItem('tokan');
     return async dispatch =>{
       const response = await fetch(`https://app.packersbilling.in/api/update-bank/${bankId}`, {
         method : "PUT",
         headers: {
           "Content-Type": "application/json",
           "auth-tokan":tok
         },
         body: JSON.stringify(formDataUpdate),
       });
       const data = await response.json();
       console.log("Update API",data);
       if(response.status === 200){
        return dispatch({
           type: 'ADD_DATA_SUCCESS_EDIT_BANK',
           payload: data,
         });
         return data;
       }else if (response.status === 400){
         return dispatch({
           type: 'ADD_DATA_FAILURE_EDIT_BANK',
           payload: data,
         });
       }
     }
   }
 

    // Company Bank Add API Call POST Method /api/bank-details
export const TearmsAPI = (formData) =>{
  const tok =  localStorage.getItem('tokan');
  return async dispatch =>{
    const response = await fetch('https://app.packersbilling.in/api/addTerms',{
      method : "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "auth-tokan":tok
      }
    });
    let data = await response.json();
    if(response.status === 400){
      dispatch({
        type: 'ADD_DATA_TEARMS_FAILURE',
        payload: data
      });
    }else if (response.status === 200){
      return dispatch({
        type: 'ADD_DATA_TEARMS_SUCCESS',
        payload: data,
      });
    }
  }
}

 // Company  API Call POST Method /api/getuser
 export const fatchTearmsAPI = () =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch('https://app.packersbilling.in/api/fatch-tearms',{
       method : "GET",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     console.log("Action API",data);
     if(response.status === 400){
      return dispatch({
         type: 'FETCH_DATA_FAILURE_TEARMS',
         payload: data
       });
     }else if (response.status === 200){
       return dispatch({
         type: 'FETCH_DATA_SUCCESS_TEARMS',
         payload: data,
       });
     }
   }
 }

   // Company Receipt API Call PUT Method /api/update-bank/${bankId}
   export const EditTearmsAPI = (formDataUpdate, tearmsId) =>{
    const tok =  localStorage.getItem('tokan');
     return async dispatch =>{
       const response = await fetch(`https://app.packersbilling.in/api/update-tearms/${tearmsId}`, {
         method : "PUT",
         headers: {
           "Content-Type": "application/json",
           "auth-tokan":tok
         },
         body: JSON.stringify(formDataUpdate),
       });
       const data = await response.json();
       console.log("Update API",data);
       if(response.status === 200){
        return dispatch({
           type: 'ADD_DATA_SUCCESS_EDIT_TEARMS',
           payload: data,
         });
         return data;
       }else if (response.status === 400){
         return dispatch({
           type: 'ADD_DATA_FAILURE_EDIT_TEARMS',
           payload: data,
         });
       }
     }
   }

  // Company Company Logo Add API Call POST Method /api/upload
export const photoUpload = (formData) =>{
  const tok =  localStorage.getItem('tokan');
  return async dispatch =>{
    const response = await fetch('https://app.packersbilling.in/api/upload',{
      method : "POST",
      body: formData,
      headers: {
        "auth-tokan":tok
      }
    });
    let data = await response.json();
    if(response.status === 400){
      return dispatch({
        type: 'ADD_DATA_PHOTO_FAILURE',
        payload: data
      });
    }else if (response.status === 200){
      return dispatch({
        type: 'ADD_DATA_PHOTO_SUCCESS',
        payload: data,
      });
    }
  }
}

// Company Logo API Call GET Method /api/photo
export const fatchPhotoAPI = () =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch('https://app.packersbilling.in/api/photo',{
       method : "GET",
       headers: {
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     console.log("Action API",data);
     if(response.status === 400){
      return dispatch({
         type: 'FETCH_DATA_FAILURE_PHOTO',
         payload: data
       });
     }else if (response.status === 200){
       return dispatch({
         type: 'FETCH_DATA_SUCCESS_PHOTO',
         payload: data,
       });
     }
   }
 }

   // Company Edit photo API Call PUT Method /api/update-bank/${bankId}
   export const EditPhotoAPI = (formData) =>{
    const tok =  localStorage.getItem('tokan');
     return async dispatch =>{
       const response = await fetch(`https://app.packersbilling.in/api/photo/update`, {
         method : "PUT",
         headers: {
           "auth-tokan":tok
         },
         body: formData,
       });
       const data = await response.json();
       console.log("Update API",data);
       if(response.status === 200){
        return dispatch({
           type: 'FETCH_DATA_SUCCESS_PHOTO_EDIT',
           payload: data,
         });
         return data;
       }else if (response.status === 400){
         return dispatch({
           type: 'FETCH_DATA_FAILURE_PHOTO_EDIT',
           payload: data,
         });
       }
     }
   }

  
  // Sign Upload Add API Call POST Method /api/upload
export const signUpload = (signatureData) =>{
  const tok =  localStorage.getItem('tokan');
  return async dispatch =>{
    const response = await fetch('https://app.packersbilling.in/api/saveSignature',{
      method : "POST",
      body: JSON.stringify({ signatureData }), // Assuming signatureData is a string
      headers: {
        "Content-Type": "application/json",
        "auth-tokan":tok
      }
    });
    let data = await response.json();
    if(response.status === 400){
      return dispatch({
        type: 'ADD_DATA_FAILURE_CREATE_SIGN',
        payload: data
      });
    }else if (response.status === 200){
      return dispatch({
        type: 'ADD_DATA_SUCCESS_CREATE_SIGN',
        payload: data,
      });
    }
  }
}


 // Company Sign API Call GET Method /api/fetchSignatures
   export const EditSignAPI = (editedSignatureData) =>{
    const tok =  localStorage.getItem('tokan');
     return async dispatch =>{
       const response = await fetch(`https://app.packersbilling.in/api/updateSignature`, {
         method : "PUT",
         headers: {
          "Content-Type": "application/json",
          "auth-tokan":tok
         },
         body: JSON.stringify({ editedSignatureData }), // Assuming signatureData is a string
       });
       const data = await response.json();
       console.log("Update API",data);
       if(response.status === 200){
        return dispatch({
           type: 'FETCH_DATA_SUCCESS_SIGN_EDIT',
           payload: data,
         });
         return data;
       }else if (response.status === 400){
         return dispatch({
           type: 'FETCH_DATA_FAILURE_SIGN_EDIT',
           payload: data,
         });
       }
     }
   }

// Company Sign API Call GET Method /api/fetchSignatures
export const fatchSignAPI = () =>{
  const tok =  localStorage.getItem('tokan');
   return async dispatch =>{
     const response = await fetch('https://app.packersbilling.in/api/fetchSignatures',{
       method : "GET",
       headers: {
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     if(response.status === 400){
      return dispatch({
         type: 'FETCH_DATA_FAILURE_SIGN',
         payload: data
       });
     }else if (response.status === 200){
       return dispatch({
         type: 'FETCH_DATA_SUCCESS_SIGN',
         payload: data,
       });
     }
   }
 }

 // Admin Login API Call POST Method /api/adminlogin
export const adminloginAPI = (formData) => {
  return async dispatch => {
      const response = await fetch('https://app.packersbilling.in/api/adminlogin',{
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json"
        }
      });
      let data = await response.json();
      if(response.status === 400){
        dispatch({
          type: 'ADD_DATA_FAILURE_ADMIN',
          payload: data
        });
      }else if (response.status === 200){
        localStorage.setItem('admin-tokan',data.authToken);
        return dispatch({
          type: 'ADD_DATA_SUCCESS_ADMIN',
          payload: data,
        });
      }
  };
};

 // Fatch Admin User
 export const fatchAllUserAPI = () =>{
  const tok =  localStorage.getItem('admin-tokan');
   return async dispatch =>{
     const response = await fetch('https://app.packersbilling.in/api/getalluser',{
       method : "POST",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       }
     });
     let data = await response.json();
     if(response.status === 400){
      return dispatch({
         type: 'ADD_DATA_FAILURE_ALLUSER',
         payload: data
       });
     }else if (response.status === 200){
       return dispatch({
         type: 'ADD_DATA_SUCCESS_ALLUSER',
         payload: data,
       });
     }
   }
 }

 // Company Receipt API Call PUT Method /api/update-user/${userId}
export const editallUserAPI = (formData, userId) =>{
  const tok =  localStorage.getItem('admin-tokan');
     const updatedFormData = { ...formData };
    // List of date properties to be updated
  const dateProperties = ['expire_date'];

  dateProperties.forEach(property => {
    // Check if the property exists in formData and is an instance of Date
    if (updatedFormData[property] instanceof Date) {
      // Format the date property before updating it
      updatedFormData[property].setHours(0, 0, 0, 0);
      const day = String(updatedFormData[property].getDate()).padStart(2, '0');
      const month = String(updatedFormData[property].getMonth() + 1).padStart(2, '0');
      const year = String(updatedFormData[property].getFullYear());
      const formattedDate = `${day}-${month}-${year}`;

      // Update the date property in updatedFormData with the formatted date
      updatedFormData[property] = formattedDate;
    }
  });
   return async dispatch =>{
     const response = await fetch(`https://app.packersbilling.in/api/update-alluser/${userId}`, {
       method : "PUT",
       headers: {
         "Content-Type": "application/json",
         "auth-tokan":tok
       },
       body: JSON.stringify(updatedFormData),
     });
     const data = await response.json();
     if(response.status === 200){
      return dispatch({
         type: 'ADD_DATA_SUCCESS_EDIT_ADMINUSER',
         payload: data,
       });
       return data;
     }else if (response.status === 400){
       return dispatch({
         type: 'ADD_DATA_FAILURE_EDIT_ADMINUSER',
         payload: data,
       });
     }
   }
 }