import React from 'react'
import {NavLink} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useDispatch } from "react-redux";
import { navbarAction } from "../redux/actionCreation";
function MobileNavigation() {
  const dispatch = useDispatch();
  return (
    <>
      
    <Nav variant="pills" activeKey="1" className='nav fixed-bottom-c mb'>
      <Nav.Item className="nav-item text-center nav-item2">
        <NavLink eventKey="1" to="/" className="nav-link p-0">
            <p className="mb-0"><i className="fa fa-home"></i></p>
            Dashboard
        </NavLink>
      </Nav.Item>
     
      <Nav.Item className="nav-item text-center nav-item2">
        <NavLink to="/list-bills" className="nav-link p-0">
            <p className="mb-0"><i className="fa fa-file-invoice"></i></p>
                 Bills
        </NavLink>
      </Nav.Item>

      <Nav.Item className="nav-item text-center nav-item2">
        <NavLink to="/quotationList-list" className="nav-link p-0">
            <p className="mb-0"><i className="fa fa-file"></i></p>
                Quotations
        </NavLink>
      </Nav.Item>

      <Nav.Item className="nav-item text-center nav-item2">
        <NavLink to="/receipts-list" className="nav-link p-0">
            <p className="mb-0"><i className="fa fa-receipt"></i></p>
                Receipts
        </NavLink>
      </Nav.Item>

      <Nav.Item className="nav-item text-center nav-item2">
        <NavLink className="nav-link more-btn p-0" onClick={()=>{dispatch(navbarAction())}}>
            <p className="mb-0"><i className="fa fa-ellipsis-h"></i></p>
                More
        </NavLink>
      </Nav.Item>
    </Nav>
    </>
  )
}

export default MobileNavigation
