import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import {useDispatch, useSelector } from 'react-redux';
import { fatchReciptAPI,fatchQuotationAPI,fatchBillAPI,DeleteBillAPI,DeleteQuotationAPI,DeleteReciptsAPI,EditBillAPI,EditQuotationAPI,EditReciptAPI } from '../redux/actionCreation';
import { parseISO, format, getMonth, isSameMonth } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import Loader from './Loader';

function Dashboard() {
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const [loading, setLoading] = useState(false);
    const [loading_update_bill, setloading_update_bill] = useState(false);
    const [loading_update_qute, setloading_update_qute] = useState(false);
    const [loading_update_recipt, setloading_update_recipt] = useState(false);
    const dispatch = useDispatch();
    const refBill = useRef(null);
    const ref2Bill = useRef(null);
    const refQuote = useRef(null);
    const ref2Quote = useRef(null);
    const refRecipt = useRef(null);
    const ref2Recipt = useRef(null);
    const navigate = useNavigate();
    let receiptCounter = 0;
    let quoteCounter = 0;
    let billCounter = 0;
    const sidebarState = useSelector(state => state.sidebarReducres);
    /* 1. Bill Fetching Data Start*/
    const fetchBillReducers = useSelector((state) => state.fetchBillReducers);
    const totalBill = fetchBillReducers.data ? fetchBillReducers.data.length : 0;
    
    const currentMonthBills = fetchBillReducers?.data?.filter(item => isSameMonth(parseISO(item.bill_date), new Date())) || [];
     const totalBillMonth = currentMonthBills.length;
     console.log("fetchBillReducers.data", fetchBillReducers.data);
     console.log("currentMonthBills", currentMonthBills);
  // Log the result
  console.log("totalBillMonth", totalBillMonth);
    const [selectedBillId, setSelectedBillId] = useState(null);
    const error_edit = useSelector(state => state.EditBillReducers.error?.edit_bill_errors) ?? null;
    /*1. Bill Fetching Data End*/

    /* 2. Bill Delete Data Start*/
    const DeleteBill = async (billId) => {
        // Show confirmation SweetAlert
        const result = await Swal.fire({
            title: `Are you sure Delete Invoice ?`,
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#673ab7',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
    
        if (result.isConfirmed) {
            try {
                // User clicked "Yes, delete it!" in the confirmation SweetAlert
                await dispatch(DeleteBillAPI(billId));
                dispatch(fatchBillAPI());
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            // User clicked "Cancel" or closed the SweetAlert
            console.log('Deletion cancelled.');
        }
    };
    /* 2. Bill Delete Data End*/

    /* 3 Update Bill Function*/
    const [formData, setFormData] = useState({  
        name: '',
        phone_number: '',
        bill_date: '',
        customer_address: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        shift_items: '',
        transportation_charge: '',
        packing_charge: '',
        unpacking_charge: '',
        loading_charge: '',
        unloading_charge: '',
        insurance_charge: '',
        service_charge: '',
        storage_charge: '',
        other_charge: '',
        total_charge: '',
        sgst_per: '',
        sgst_charge: '',
        gst_per: '',
        gst_charge: '',
        cgst_per: '',
        cgst_charge: '',
      });

            // Function to calculate total of specified fields
   const BillcalculateTotal = () => {
    const {
      transportation_charge,
      packing_charge,
      unpacking_charge,
      loading_charge,
      unloading_charge,
      insurance_charge,
      storage_charge,
      other_charge
    } = formData;

    const total =
      parseFloat(transportation_charge) +
      parseFloat(packing_charge) +
      parseFloat(unpacking_charge) +
      parseFloat(loading_charge) +
      parseFloat(unloading_charge) +
      parseFloat(insurance_charge) +
      parseFloat(storage_charge) +
      parseFloat(other_charge);
    return total;
  };

    // Function to calculate GST charge based on total and gst_per
    const calculateGSTChargebill = (total, gstPercentage) => {
        return (total * parseFloat(gstPercentage)) / 100;
    };

     // Function to calculate GST charge based on total and gst_per
     const calculateSGSTChargebill = (total, sgstPercentage) => {
        return (total * parseFloat(sgstPercentage)) / 100;
    };

    // Function to calculate GST charge based on total and cgst_per
    const calculateCGSTChargebill = (total, cgstPercentage) => {
        return (total * parseFloat(cgstPercentage)) / 100;
    };
  
    // useEffect to watch for changes in relevant fields and recalculate charges
    useEffect(() => {
        const total = BillcalculateTotal();
        const sgstCharge = calculateSGSTChargebill(total, formData.sgst_per);
        const gstCharge = calculateGSTChargebill(total, formData.gst_per);
        const cgstCharge = calculateCGSTChargebill(total, formData.cgst_per);
        // Update state with the calculated values
        setFormData((prevData) => ({
        ...prevData,
        total_charge:total.toFixed(2),
        sgst_charge: sgstCharge.toFixed(2), // Adjust decimal places as needed
        gst_charge: gstCharge.toFixed(2), // Adjust decimal places as needed
        cgst_charge: cgstCharge.toFixed(2), // Adjust decimal places as needed
        }));
    }, [
        formData.transportation_charge,
        formData.packing_charge,
        formData.unpacking_charge,
        formData.loading_charge,
        formData.unloading_charge,
        formData.insurance_charge,
        formData.service_charge,
        formData.storage_charge,
        formData.other_charge,
        formData.sgst_per,
        formData.gst_per,
        formData.cgst_per,
    ]);
  

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };

    // Function to handle link click
    const UpdateBillClick = (billId) => {
        setSelectedBillId(billId);
        const selectedBill = fetchBillReducers.data.find(bill => bill.id === billId);
        if (selectedBill) {
            const formattedDate = formatDate(selectedBill.bill_date);
                const shift_start_date = formatDate(selectedBill.shift_start_date);
                const shift_end_date = formatDate(selectedBill.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
            setFormData({
                ...formData,
                name: selectedBill.name,
                phone_number: selectedBill.phone_number,
                bill_date: new Date(formattedDate + 'T00:00:00'),
                customer_address: selectedBill.customer_address,
                service_name: selectedBill.service_name,
                from_city: selectedBill.from_city,
                to_city: selectedBill.to_city,
                shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                shift_items: selectedBill.shift_items,
                transportation_charge: selectedBill.transportation_charge,
                packing_charge: selectedBill.packing_charge,
                unpacking_charge: selectedBill.unpacking_charge,
                loading_charge: selectedBill.loading_charge,
                unloading_charge: selectedBill.unloading_charge,
                insurance_charge: selectedBill.insurance_charge,
                service_charge: selectedBill.service_charge,
                storage_charge: selectedBill.storage_charge,
                other_charge: selectedBill.other_charge,
                sgst_per: selectedBill.sgst_per,
                sgst_charge: selectedBill.sgst_charge,
                gst_per: selectedBill.gst_per,
                gst_charge: selectedBill.gst_charge,
                cgst_charge: selectedBill.cgst_charge,
                cgst_per: selectedBill.cgst_per,
              });
        } else {
            console.log("Bill not found");
        }
    };
    
    const UpdateBill = async (event) => {
        event.preventDefault();
        setloading_update_bill(true);
        try {
            const response = await dispatch(EditBillAPI(formData, selectedBillId));
            setloading_update_bill(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_BILL"){
                ref2Bill.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Bill updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
               dispatch(fatchBillAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the bill. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating bill:', error);
        }
      };

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };
    /* 3 Update Bill End Function*/

    function addStringNumbers(num1, num2) {
        const maxLength = Math.max(num1.length, num2.length);
        num1 = num1.padStart(maxLength, '0');
        num2 = num2.padStart(maxLength, '0');
        let carry = 0;
        let result = '';
        for (let i = maxLength - 1; i >= 0; i--) {
            const sum = parseInt(num1[i]) + parseInt(num2[i]) + carry;
            result = (sum % 10) + result;
            carry = Math.floor(sum / 10);
        }
        if (carry) {
            result = carry + result;
        }
        return result;
    }

    // Format number with commas
    function formatNumberWithCommas(number) {
        if (number >= 1000) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return number.toString();
        }
    }

    // Calculate total profit as a string
    const totalProfitString = fetchBillReducers.data
        ? fetchBillReducers.data.reduce((accumulator, item) => addStringNumbers(accumulator, item.total_amount || '0'), '0')
        : '0';

    // Convert total profit string to number and format with commas
    const totalProfit = parseInt(totalProfitString);
    const formattedTotalProfit = formatNumberWithCommas(totalProfit);

      // Calculate total profit as a string for the current month
      const totalProfitForMonthString = currentMonthBills.reduce(
        (accumulator, item) => addStringNumbers(accumulator, item.total_amount || '0'), '0'
    );

    // Convert total profit string for the current month to number and format with commas
    const totalProfitForMonth = parseInt(totalProfitForMonthString);
    const formattedTotalProfitForMonth = formatNumberWithCommas(totalProfitForMonth);

    /*1. Quotation Fetching Data Start*/
    const fetchQutReducers = useSelector((state) => state.fetchQutReducers);
    const [selectedQuotId, setSelectedQuotId] = useState(null);
    const totalquotation = fetchQutReducers.data ? fetchQutReducers.data.length : 0;
    const currentMonthQuotation = fetchQutReducers?.data?.filter(item => isSameMonth(parseISO(item.quotation_date), new Date())) || [];
    const error_edit_qute = useSelector(state => state.EditQuotationReducers.error?.edit_quotation_errors) ?? null;
    const totalQuotationMonth = currentMonthQuotation.length;
    /*2. Quotation Fetching Data Start*/
    
    /* 2. Quotation Delete Data Start*/
      const DeleteQuotation = async (billId) => {
        // Show confirmation SweetAlert
        const result = await Swal.fire({
            title: `Are you sure Delete Quotation ?`,
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#673ab7',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
    
        if (result.isConfirmed) {
            try {
                await dispatch(DeleteQuotationAPI(billId));
                dispatch(fatchQuotationAPI());
            } catch (error) {
            //   console.error('Error:', error);
            }
        } else {
            // User clicked "Cancel" or closed the SweetAlert
            console.log('Deletion cancelled.');
        }
    }
    /* 2. Quotation Delete Data End*/


      /*3 Update Quotation Data start */
      const [formDataQuote, setFormDataQuote] = useState({  
        name: '',
        phone_number: '',
        quotation_date: '',
        customer_address: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        shift_items: '',
        transportation_charge: '',
        packing_charge: '',
        unpacking_charge: '',
        loading_charge: '',
        unloading_charge: '',
        insurance_charge: '',
        service_charge: '',
        storage_charge: '',
        other_charge: '',
        total_charge: '',
        sgst_per: '',
        sgst_charge: '',
        gst_per: '',
        gst_charge: '',
        cgst_per: '',
        cgst_charge: '',
      });
      

          // Function to calculate total of specified fields
   const calculateTotal = () => {
    const {
      transportation_charge,
      packing_charge,
      unpacking_charge,
      loading_charge,
      unloading_charge,
      insurance_charge,
      storage_charge,
      other_charge
    } = formDataQuote;

    const total =
      parseFloat(transportation_charge) +
      parseFloat(packing_charge) +
      parseFloat(unpacking_charge) +
      parseFloat(loading_charge) +
      parseFloat(unloading_charge) +
      parseFloat(insurance_charge) +
      parseFloat(storage_charge) +
      parseFloat(other_charge);
    return total;
  };

    // Function to calculate GST charge based on total and gst_per
    const calculateGSTCharge = (total, gstPercentage) => {
        return (total * parseFloat(gstPercentage)) / 100;
    };

    // Function to calculate GST charge based on total and gst_per
    const calculateSGSTCharge = (total, sgstPercentage) => {
        return (total * parseFloat(sgstPercentage)) / 100;
    };

    // Function to calculate GST charge based on total and cgst_per
    const calculateCGSTCharge = (total, cgstPercentage) => {
        return (total * parseFloat(cgstPercentage)) / 100;
    };
  
    // useEffect to watch for changes in relevant fields and recalculate charges
    useEffect(() => {
        const total = calculateTotal();
        const sgstCharge = calculateGSTCharge(total, formDataQuote.sgst_per);
        const gstCharge = calculateGSTCharge(total, formDataQuote.gst_per);
        const cgstCharge = calculateCGSTCharge(total, formDataQuote.cgst_per);
        // Update state with the calculated values
        setFormDataQuote((prevData) => ({
        ...prevData,
            total_charge:total.toFixed(2),
            sgst_charge: sgstCharge.toFixed(2), // Adjust decimal places as needed
            gst_charge: gstCharge.toFixed(2), // Adjust decimal places as needed
            cgst_charge: cgstCharge.toFixed(2), // Adjust decimal places as needed
        }));
    }, [
        formDataQuote.transportation_charge,
        formDataQuote.packing_charge,
        formDataQuote.unpacking_charge,
        formDataQuote.loading_charge,
        formDataQuote.unloading_charge,
        formDataQuote.insurance_charge,
        formDataQuote.service_charge,
        formDataQuote.storage_charge,
        formDataQuote.other_charge,
        formDataQuote.sgst_per,
        formDataQuote.gst_per,
        formDataQuote.cgst_per,
    ]);

    // Function to handle link click
    const UpdateQuotationClick = (QuoteId) => {
        setSelectedQuotId(QuoteId);
        const selectedQuote = fetchQutReducers.data.find(quotation => quotation.id === QuoteId);
        if (selectedQuote) {
                const formattedDate = formatDate(selectedQuote.quotation_date);
                const shift_start_date = formatDate(selectedQuote.shift_start_date);
                const shift_end_date = formatDate(selectedQuote.shift_end_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
            setFormDataQuote({
                ...formData,
                name: selectedQuote.name,
                phone_number: selectedQuote.phone_number,
                quotation_date: new Date(formattedDate + 'T00:00:00'),
                customer_address: selectedQuote.customer_address,
                service_name: selectedQuote.service_name,
                from_city: selectedQuote.from_city,
                to_city: selectedQuote.to_city,
                shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                shift_items: selectedQuote.shift_items,
                transportation_charge: selectedQuote.transportation_charge,
                packing_charge: selectedQuote.packing_charge,
                unpacking_charge: selectedQuote.unpacking_charge,
                loading_charge: selectedQuote.loading_charge,
                unloading_charge: selectedQuote.unloading_charge,
                insurance_charge: selectedQuote.insurance_charge,
                service_charge: selectedQuote.service_charge,
                storage_charge: selectedQuote.storage_charge,
                other_charge: selectedQuote.other_charge,
                sgst_per: selectedQuote.sgst_per,
                sgst_charge: selectedQuote.sgst_charge,
                gst_per: selectedQuote.gst_per,
                gst_charge: selectedQuote.gst_charge,
                cgst_charge: selectedQuote.cgst_charge,
                cgst_per: selectedQuote.cgst_per,
              });
        } else {
            console.log("Quotation not found");
        }
    };
    
    const handleChangeQute = (event) => {
        const { name, value } = event.target;
        setFormDataQuote(formDataQuote => ({
          ...formDataQuote,
          [name]: value
        }));
      };

    const UpdateQuotation = async (event) => {
        event.preventDefault();
        setloading_update_qute(true);
        try {
            const response = await dispatch(EditQuotationAPI(formDataQuote, selectedQuotId));
            setloading_update_qute(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_QUOTATION"){
                
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Quotation updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
               dispatch(fatchQuotationAPI());
               ref2Quote.current.click();
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Quotation. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating Quotation:', error);
        }
      };

      /*3 Update Quotation Data End */

    /*1 Recipts Fetching Data Start*/
    const fetchRecipReducers = useSelector((state) => state.fetchRecipReducers);
    const totalRecipt = fetchRecipReducers.data ? fetchRecipReducers.data.length : 0;
    const [selectedReciptsId, setselectedReciptsId] = useState(null);
    const currentMonthRecipt = fetchRecipReducers?.data?.filter(item => isSameMonth(parseISO(item.recipts_date), new Date())) || [];
    const error_edit_receipt = useSelector(state => state.EditReciptReducrs.error?.edit_receipt_errors) ?? null;
    const totalReciptsMonth = currentMonthRecipt.length;
    /*1 Recipts Fetching Data Start*/

    /*2 Delete Data Start*/
    const DeleteRecipt = async (reciptsId) => {
        // Show confirmation SweetAlert
        const result = await Swal.fire({
            title: `Are you sure Delete Receipt ?`,
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#673ab7',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        });
    
        if (result.isConfirmed) {
            try {
                await dispatch(DeleteReciptsAPI(reciptsId));
                dispatch(fatchReciptAPI());
            } catch (error) {
            //   console.error('Error:', error);
            }
        } else {
            // User clicked "Cancel" or closed the SweetAlert
            console.log('Deletion cancelled.');
        }
    };
      /*2 Delete Data End*/

    // Update Recipts Function
    const [formDataReceipt, setFormDataReceipt] = useState({  
        name: '',
        phone_number: '',
        recipts_date: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        payment_type: '',
        total_amount: '',
        received_amount: ''
      });

      // Function to handle link click
    const UpdateReceiptClick = (reciptId) => {
        setselectedReciptsId(reciptId);
        const selectedRecipt = fetchRecipReducers.data.find(recipt => recipt.id === reciptId);
        if (selectedRecipt) {
            const formattedDate = formatDate(selectedRecipt.recipts_date);
            const shift_start_date = formatDate(selectedRecipt.shift_start_date);
            const shift_end_date = formatDate(selectedRecipt.shift_end_date);
            const defaultTime = new Date();
            defaultTime.setHours(0, 0, 0, 0);
            
            setFormDataReceipt({
                ...formData,
                name: selectedRecipt.name,
                phone_number: selectedRecipt.phone_number,
                recipts_date: new Date(formattedDate + 'T00:00:00'),
                service_name: selectedRecipt.service_name,
                from_city: selectedRecipt.from_city,
                to_city: selectedRecipt.to_city,
                shift_start_date: new Date(shift_start_date + 'T00:00:00'),
                shift_end_date: new Date(shift_end_date + 'T00:00:00'),
                payment_type: selectedRecipt.payment_type,
                total_amount: selectedRecipt.total_amount,
                received_amount: selectedRecipt.received_amount
              });
        } else {
            console.log("Recipts not found");
        }
    };

    const UpdateRecipt = async (event) => {
        event.preventDefault();
        setloading_update_recipt(true);
        try {
            const response = await dispatch(EditReciptAPI(formDataReceipt, selectedReciptsId));
            setloading_update_recipt(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_RECEIPT"){
                ref2Recipt.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Receipt updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
               dispatch(fatchReciptAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Receipt. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating bill:', error);
        }
      };

      const handleChangeRecipt = (event) => {
        const { name, value } = event.target;
        setFormDataReceipt(formDataReceipt => ({
          ...formDataReceipt,
          [name]: value
        }));
      };

      const handleReceiptDateChange = (date) => {
        // Create a new Date object with the same values to avoid modifying the original object
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
    
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
    
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormDataReceipt((prevState) => ({
            ...prevState,
            recipts_date: modifiedDate,
        }));
    };

    const handleBillDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            bill_date: modifiedDate,
        }));
    };

    const handleQuoteDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormDataQuote((prevState) => ({
            ...prevState,
            quotation_date: modifiedDate,
        }));
    };
    


    const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormDataReceipt((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
        setFormDataQuote((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };

      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormDataReceipt((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
        setFormDataQuote((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };

    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const expire_date = fetchUserReducres.data ? new Date(fetchUserReducres.data.expire_date) : null;
    const [formattedExpireDate, setFormattedExpireDate] = useState(null);
  
    useEffect(() => {
      if (expire_date instanceof Date && !isNaN(expire_date.getTime())) {
        // Calculate the difference in milliseconds between the current date and expiration date
        const timeDifference = expire_date.getTime() - new Date().getTime();
  
        // Calculate the number of days
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  
        // Show a message if the expiration date is within the next 3 days
        if (daysDifference <= 3) {
          const formattedDate = format(parseISO(expire_date.toISOString()), "dd-MM-yyyy");
          setFormattedExpireDate(formattedDate);
        }
      }
    }, [expire_date]);

    // Fetch Quotations Data
    useEffect(() => {
        const fetchDataQuotation = async () => {
            setLoading(true);
        try {
            const res = await dispatch(fatchQuotationAPI());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching quotations data:', error);
        }
        };
        fetchDataQuotation();
    }, [dispatch]);

    // Fetch Receipts Data
    useEffect(() => {
        const fetchDataRecipts = async () => {
            setLoading(true);
        try {
            const res = await dispatch(fatchReciptAPI());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching receipts data:', error);
        }
        };
        fetchDataRecipts();
    }, [dispatch]);

    // Bill Data fatch
    useEffect(() => {
        const fetchDataBill = async () => {
            setLoading(true);
        try {
            const res = await dispatch(fatchBillAPI());
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching receipts data:', error);
        }
        };
        fetchDataBill();
    }, [dispatch]);

  return (
    <>
    <FixedButton/>
    <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
        <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                        <div className='d-mob-block d-flex align-items-center justify-content-between mb-2'>
                            <h5 className='mb-2'>Dashboard</h5>
                            {/* <p className='mb-2 notification-exp'>Your package is expiring soon on <strong>{formattedExpireDate}</strong></p> */}
                            {formattedExpireDate ? (
                                    <marquee behavior="alternate"><p className='mb-2 notification-exp'>Your package is expiring soon on <strong>{formattedExpireDate}</strong>.</p></marquee>
                                ) : (
                                    <p></p>
                                )}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count">
                            <div className="dash-counts">
                                <h4>{totalBill}</h4>
                                <h5>Total Invoice</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-file-invoice"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count das1">
                            <div className="dash-counts">
                                <h4>{totalBillMonth}</h4>
                                <h5>This Month Invoice</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-file-invoice"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count das2">
                            <div className="dash-counts">
                                <h4>{totalquotation}</h4>
                                <h5>Total Quotations</h5>
                            </div>
                            <div className="dash-imgs">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count das3">
                            <div className="dash-counts">
                                <h4>{totalQuotationMonth}</h4>
                                <h5>This Month Quotations</h5>
                            </div>
                            <div className="dash-imgs">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            </div>
				        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count">
                            <div className="dash-counts">
                                <h4>{totalRecipt}</h4>
                                <h5>Total Receipts</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-receipt"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count das1">
                            <div className="dash-counts">

                                <h4>{totalReciptsMonth}</h4>
                                <h5>This Month Receipts</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-receipt"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count das2">
                            <div className="dash-counts">
                                <h4>{formattedTotalProfit}</h4>
                                <h5>Total Profit</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-indian-rupee-sign"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="3" lg="3" xl="3" xxl="3" className='d-flex'>
                        <div className="dash-count das3">
                            <div className="dash-counts">
                                <h4>{formattedTotalProfitForMonth}</h4>
                                <h5>This Month Profit</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-indian-rupee-sign"></i>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='text-center'>
                    {loading && <Loader />}
                </div>  
                {!loading && (                 
                <Row>
                    {/* Recent Bill */}
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                        <h6 className='mt-3'>Recent Invoice </h6>
                            <Row>
                                {fetchBillReducers && Array.isArray(fetchBillReducers.data) && fetchBillReducers.data.length > 0 ? (
                                fetchBillReducers.data.slice(0, 5).reverse().map(item => (
                                    <Col key={item.id} xs="12" sm="12" md="12" lg="12" xl="12" xxl="6">
                                    <div className="dash-count bill_box das3">
                                        <div className="dash-counts">
                                            <h4 className="text-dark c_name">{item.name}</h4>
                                            <h5 className="text-dark text-green">Invoice #{item.invoice_number}</h5>
                                            {item.bill_date && (
                                                <h5 className="text-dark">
                                                {format(parseISO(item.bill_date), 'dd-MM-yyyy')}
                                                </h5>
                                            )}
                                        </div>
                                        <div className="dash-imgs">
                                            <i className="text-dark invoice_i fa fa-indian-rupee-sign mr-0"></i>
                                            <span className="amount text-dark">{item.total_amount}</span>
                                                <p>
                                                    <Link to={`/invoiceedit/${item.id}`}>
                                                        <i className='view_invoice_i pr-3 fa fa-eye'></i>
                                                    </Link>

                                                    <Link onClick={() => UpdateBillClick(item.id)}
                                                    type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                                        <i className='view_invoice_i pr-3 fa fa-edit'></i>
                                                    </Link>

                                                    <Link to="#" onClick={() => DeleteBill(item.id)}>
                                                        <i className='delet_invoice_i pr-3 fa fa-trash'></i>
                                                    </Link>
                                                </p>
                                        </div>
                                    </div>
                                    </Col>
                                    ))
                                    ) : (
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <div className='text-center not_data bill_box'>
                                            <i class="fa-solid fa-triangle-exclamation d-block text-center"></i>
                                            <p className='text-center'>No data available</p>
                                        </div>
                                    </Col>
                                    )}
                            </Row>
                    </Col>
                    {/* Recent Bill */}

                     {/* Recent Quotation */}
                        <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                            <h6 className='mt-3'>Recent Quotation </h6>
                                <Row>
                                {fetchQutReducers && Array.isArray(fetchQutReducers.data) && fetchQutReducers.data.length > 0 ? (
                                fetchQutReducers.data.slice(0, 5).reverse().map(item => (
                                    <Col key={item.id} xs="12" sm="12" md="12" lg="12" xl="12" xxl="6">
                                    <div className="dash-count bill_box das3">
                                        <div className="dash-counts">
                                            <h4 className="text-dark c_name">{item.name}</h4>
                                            <h5 className="text-dark text-green">Quotation #{item.quotation_number}</h5>
                                            {item.quotation_date && (
                                                <h5 className="text-dark">
                                                {format(parseISO(item.quotation_date), 'dd-MM-yyyy')}
                                                </h5>
                                            )}
                                        </div>
                                        <div className="dash-imgs">
                                            <i className="text-dark invoice_i fa fa-indian-rupee-sign mr-0"></i>
                                            <span className="amount text-dark">{item.total_amount}</span>
                                                <p>
                                                    <Link to={`/quotationedit/${item.id}`}>
                                                        <i className='view_invoice_i pr-3 fa fa-eye'></i>
                                                    </Link>

                                                    <Link onClick={() => UpdateQuotationClick(item.id)}type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop2" aria-controls="staticBackdrop">
                                                        <i className='view_invoice_i pr-3 fa fa-edit'></i>
                                                    </Link>

                                                    <Link to="#" onClick={() => DeleteQuotation(item.id)}>
                                                        <i className='delet_invoice_i pr-3 fa fa-trash'></i>
                                                    </Link>
                                                </p>
                                        </div>
                                    </div>
                                    </Col>
                                    ))
                                    ) : (
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <div className='text-center not_data bill_box'>
                                            <i class="fa-solid fa-triangle-exclamation d-block text-center"></i>
                                            <p className='text-center'>No data available</p>
                                        </div>
                                    </Col>
                                    )}
                                </Row>
                        </Col>
                    {/* Recent Quotation */}

                   {/* Recent Recipts */}
                   <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                        <h6 className='mt-3'>Recent Recipts </h6>
                            <Row>
                                {fetchRecipReducers && Array.isArray(fetchRecipReducers.data) && fetchRecipReducers.data.length > 0 ? (
                                fetchRecipReducers.data.slice(0, 5).reverse().map(item => (
                                    <Col key={item.id} xs="12" sm="12" md="12" lg="12" xl="12" xxl="6">
                                    <div className="dash-count bill_box das3">
                                        <div className="dash-counts">
                                            <h4 className="text-dark c_name">{item.name}</h4>
                                            <h5 className="text-dark text-green">Recipts #{item.receipt_number}</h5>
                                            {item.recipts_date && (
                                                <h5 className="text-dark">
                                                {format(parseISO(item.recipts_date), 'dd-MM-yyyy')}
                                                </h5>
                                            )}
                                        </div>
                                        <div className="dash-imgs">
                                            <i className="text-dark invoice_i fa fa-indian-rupee-sign mr-0"></i>
                                            <span className="amount text-dark">{item.total_amount}</span>
                                            {item.pending_amount !== 0 && (
                                                <span className="pending_amount text-dark">
                                                    Unpaid <i className="text-dark fa fa-indian-rupee-sign mr-0"></i> {item.pending_amount}
                                                </span>
                                                )}
                                                <p>
                                                <Link to={`/recipteedit/${item.id}`}>
                                                    <i className='view_invoice_i pr-3 fa fa-eye'></i>
                                                </Link>

                                                    <Link
                                                    onClick={() => UpdateReceiptClick(item.id)}
                                                     type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop3" aria-controls="staticBackdrop">
                                                        <i className='view_invoice_i pr-3 fa fa-edit'></i>
                                                    </Link>


                                                    <Link to="#" onClick={() => DeleteRecipt(item.id)}>
                                                        <i className='delet_invoice_i pr-3 fa fa-trash'></i>
                                                    </Link>
                                                </p>
                                        </div>
                                    </div>
                                    </Col>
                                    ))
                                    ) : (
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <div className='text-center not_data bill_box'>
                                            <i class="fa-solid fa-triangle-exclamation d-block text-center"></i>
                                            <p className='text-center'>No data available</p>
                                        </div>
                                    </Col>
                                    )}
                            </Row>
                    </Col>
                    {/* Recent Quotation */}
                </Row>
                )}
            </Container>
        </div>
        <MobileNavigation/>

        {/* Edit Invoice Sidebar Start*/}
        <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Invoice</h5>
                    <button type="button" ref={ref2Bill} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateBill}>
                            <Row className="mb-3">
                            <h6 className='mb-0'>General Details:</h6>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                    <hr className='w-10'/>
                                </Col>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                    <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>
                                        {error_edit?.name && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                            {error_edit.name && error_edit.name}
                                        </span>
                                        )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Customer Phone.." onChange={handleChange} name="phone_number" value={formData.phone_number} required/>

                                            {error_edit?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                            {error_edit.phone_greater && error_edit.phone_greater}
                                            </span>
                                            )}

                                            {error_edit?.phone_less && (
                                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                                {error_edit.phone_less && error_edit.phone_less}
                                                </span>
                                            )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                    <Form.Label>Bill Date</Form.Label>
                                        <div className="date-picker-container">
                                            <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.bill_date}
                                                    onChange={handleBillDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Bill Date"
                                                    className='form-control'
                                                    required
                                                />
                                        </div>
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                    <Form.Label>Customer Address</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Customer Address.." onChange={handleChange} name="customer_address" value={formData.customer_address} required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="4" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control type="text" placeholder="Service Name.." onChange={handleChange} name="service_name" value={formData.service_name} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>From City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="From.." onChange={handleChange} name="from_city" value={formData.from_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="To.." onChange={handleChange} name="to_city" value={formData.to_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit?.shift_end_date_final && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit.shift_end_date_final && error_edit.shift_end_date_final}
                                            </span>
                                            )}
                                        </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                                <Form.Label>Shifting Items</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Shifting Items.." onChange={handleChange} name="shift_items" value={formData.shift_items} required/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Transportation Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Transportation Charge.." onChange={handleChange} name="transportation_charge" value={formData.transportation_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Packing Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Packing Charge.." onChange={handleChange} name="packing_charge" value={formData.packing_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Unpacking Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unpacking Charge.." onChange={handleChange} name="unpacking_charge" value={formData.unpacking_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Loading Charge</Form.Label>
                                                <Form.Control type="number" onChange={handleChange} placeholder="Loading Charge.." name="loading_charge" value={formData.loading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Unloading Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unloading Charge.." onChange={handleChange} name="unloading_charge" value={formData.unloading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Insurance Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Insurance Charge.." onChange={handleChange} name="insurance_charge" value={formData.insurance_charge} required/>
                                            </Form.Group>
                                            

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Storage Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Storage Charge.." onChange={handleChange} name="storage_charge" value={formData.storage_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Other Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Other Charge.." onChange={handleChange} name="other_charge" value={formData.other_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control type="number" placeholder="Total.." name="total_charge" value={BillcalculateTotal()} readOnly/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Tax Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select SGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChange} name="sgst_per" value={formData.sgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="3">3%</option>
                                                    <option value="4">4%</option>
                                                    <option value="5">5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="7">7%</option>
                                                    <option value="8">8%</option>
                                                    <option value="9">9%</option>
                                                    <option value="10">10%</option>
                                                    <option value="11">11%</option>
                                                    <option value="12">12%</option>
                                                    <option value="13">13%</option>
                                                    <option value="14">14%</option>
                                                </select>
                                                {error_edit?.sgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit.sgst_per && error_edit.sgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>SGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="SGST Charge.." name="sgst_charge" value={formData.sgst_charge} readOnly/>
                                            </Form.Group>


                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select CGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChange} name="cgst_per" value={formData.cgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="3">3%</option>
                                                    <option value="4">4%</option>
                                                    <option value="5">5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="7">7%</option>
                                                    <option value="8">8%</option>
                                                    <option value="9">9%</option>
                                                    <option value="10">10%</option>
                                                    <option value="11">11%</option>
                                                    <option value="12">12%</option>
                                                    <option value="13">13%</option>
                                                    <option value="14">14%</option>
                                                </select>
                                                {error_edit?.cgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit.cgst_per && error_edit.cgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>CGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="CGST Charge.." name="cgst_charge" value={formData.cgst_charge} readOnly/>
                                            </Form.Group>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect">Select GST %</Form.Label>
                                            <select className='form-select' id="exampleSelect" onChange={handleChange} name="gst_per" value={formData.gst_per} required>
                                            <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="3">3%</option>
                                                <option value="4">4%</option>
                                                <option value="5">5%</option>
                                                <option value="6">6%</option>
                                                <option value="7">7%</option>
                                                <option value="8">8%</option>
                                                <option value="9">9%</option>
                                                <option value="10">10%</option>
                                                <option value="11">11%</option>
                                                <option value="12">12%</option>
                                                <option value="13">13%</option>
                                                <option value="14">14%</option>
                                                <option value="15">15%</option>
                                                <option value="16">16%</option>
                                                <option value="17">17%</option>
                                                <option value="18">18%</option>
                                                <option value="19">19%</option>
                                                <option value="20">20%</option>
                                                <option value="21">21%</option>
                                                <option value="22">22%</option>
                                                <option value="23">23%</option>
                                                <option value="24">24%</option>
                                                <option value="25">25%</option>
                                                <option value="26">26%</option>
                                                <option value="27">27%</option>
                                                <option value="28">28%</option>
                                            </select>
                                            {error_edit?.gst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error_edit.gst_per && error_edit.gst_per}
                                            </span>
                                            )}
                                        </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>GST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="GST Charge.." name="gst_charge" value={formData.gst_charge} readOnly/>
                                            </Form.Group>

                                            

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Service Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Service Charge.." onChange={handleChange} name="service_charge" value={formData.service_charge} required/>
                                            </Form.Group>
                                        </Row>

                                <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                    <span className='mx-2'>Update</span>
                                    {loading_update_bill && <span className='spin-sm'><Loader /></span>}
                                </Button>
                        </Form>
                    </div>
                </div>
        </div>
        {/* Edit Invoice Sidebar End */}
        
        {/* Edit Quotation Sidebar Start*/}
        <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop2" aria-labelledby="staticBackdropLabel2" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel2">Edit Quotation</h5>
                    <button type="button" ref={ref2Quote} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateQuotation}>
                            <Row className="mb-3">
                            <h6 className='mb-0'>General Details:</h6>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                    <hr className='w-10'/>
                                </Col>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                    <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChangeQute} placeholder="Customer Name.." name="name" value={formDataQuote.name} required/>
                                        {error_edit_qute?.name && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                            {error_edit_qute.name && error_edit_qute.name}
                                        </span>
                                        )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" placeholder="Customer Phone.." onChange={handleChangeQute} name="phone_number" value={formDataQuote.phone_number} required/>

                                            {error_edit_qute?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                            {error_edit_qute.phone_greater && error_edit_qute.phone_greater}
                                            </span>
                                            )}

                                            {error_edit_qute?.phone_less && (
                                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                                {error_edit_qute.phone_less && error_edit_qute.phone_less}
                                                </span>
                                            )}
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                    <Form.Label>Quotation Date</Form.Label>
                                        <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formDataQuote.quotation_date}
                                                    onChange={handleQuoteDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Quotation Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                </Form.Group>

                                <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                    <Form.Label>Customer Address</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Customer Address.." onChange={handleChangeQute} name="customer_address" value={formDataQuote.customer_address} required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="4" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control type="text" placeholder="Service Name.." onChange={handleChangeQute} name="service_name" value={formDataQuote.service_name} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>From City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="From.." onChange={handleChangeQute} name="from_city" value={formDataQuote.from_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" placeholder="To.." onChange={handleChangeQute} name="to_city" value={formDataQuote.to_city} required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formDataQuote.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="2" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formDataQuote.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit_qute?.shift_end_date_final && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit_qute.shift_end_date_final && error_edit_qute.shift_end_date_final}
                                            </span>
                                            )}
                                        </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                                <Form.Label>Shifting Items</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Shifting Items.." onChange={handleChangeQute} name="shift_items" value={formDataQuote.shift_items} required/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Transportation Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Transportation Charge.." onChange={handleChangeQute} name="transportation_charge" value={formDataQuote.transportation_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Packing Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Packing Charge.." onChange={handleChangeQute} name="packing_charge" value={formDataQuote.packing_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Unpacking Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unpacking Charge.." onChange={handleChangeQute} name="unpacking_charge" value={formDataQuote.unpacking_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Loading Charge</Form.Label>
                                                <Form.Control type="number" onChange={handleChangeQute} placeholder="Loading Charge.." name="loading_charge" value={formDataQuote.loading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Unloading Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Unloading Charge.." onChange={handleChangeQute} name="unloading_charge" value={formDataQuote.unloading_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Insurance Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Insurance Charge.." onChange={handleChangeQute} name="insurance_charge" value={formDataQuote.insurance_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword"  className='mt-2'>
                                                <Form.Label>Storage Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Storage Charge.." onChange={handleChangeQute} name="storage_charge" value={formDataQuote.storage_charge} required/>
                                            </Form.Group>
                                            
                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Other Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Other Charge.." onChange={handleChangeQute} name="other_charge" value={formDataQuote.other_charge} required/>
                                            </Form.Group>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Total</Form.Label>
                                                <Form.Control type="number" placeholder="Total.." name="total_charge" value={calculateTotal()} readOnly/>
                                            </Form.Group>

                                            <h6 className='mb-0 mt-3'>Tax Charges :</h6>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                                <hr className='w-10'/>
                                            </Col>
                                            
                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select SGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChangeQute} name="sgst_per" value={formDataQuote.sgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="3">3%</option>
                                                    <option value="4">4%</option>
                                                    <option value="5">5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="7">7%</option>
                                                    <option value="8">8%</option>
                                                    <option value="9">9%</option>
                                                    <option value="10">10%</option>
                                                    <option value="11">11%</option>
                                                    <option value="12">12%</option>
                                                    <option value="13">13%</option>
                                                    <option value="14">14%</option>
                                                </select>
                                                {error_edit_qute?.sgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit_qute.sgst_per && error_edit_qute.sgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>SGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="CGST Charge.." name="sgst_charge" value={formDataQuote.sgst_charge} readOnly/>
                                            </Form.Group>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect2">Select CGST %</Form.Label>
                                                <select className='form-select' id="exampleSelect2" onChange={handleChangeQute} name="cgst_per" value={formDataQuote.cgst_per} required>
                                                    <option value="">Select % </option>
                                                    <option value="0">0%</option>
                                                    <option value="1">1%</option>
                                                    <option value="2">2%</option>
                                                    <option value="3">3%</option>
                                                    <option value="4">4%</option>
                                                    <option value="5">5%</option>
                                                    <option value="6">6%</option>
                                                    <option value="7">7%</option>
                                                    <option value="8">8%</option>
                                                    <option value="9">9%</option>
                                                    <option value="10">10%</option>
                                                    <option value="11">11%</option>
                                                    <option value="12">12%</option>
                                                    <option value="13">13%</option>
                                                    <option value="14">14%</option>
                                                </select>
                                                {error_edit_qute?.cgst_per && (
                                                <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                    {error_edit_qute.cgst_per && error_edit_qute.cgst_per}
                                                </span>
                                            )}
                                            </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>CGST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="CGST Charge.." name="cgst_charge" value={formDataQuote.cgst_charge} readOnly/>
                                            </Form.Group>

                                            <div className='form-group mt-2 col-md-6 col-lg-6 col-sm-12 col-xs-12'>
                                            <Form.Label for="exampleSelect">Select GST %</Form.Label>
                                            <select className='form-select' id="exampleSelect" onChange={handleChangeQute} name="gst_per" value={formDataQuote.gst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="3">3%</option>
                                                <option value="4">4%</option>
                                                <option value="5">5%</option>
                                                <option value="6">6%</option>
                                                <option value="7">7%</option>
                                                <option value="8">8%</option>
                                                <option value="9">9%</option>
                                                <option value="10">10%</option>
                                                <option value="11">11%</option>
                                                <option value="12">12%</option>
                                                <option value="13">13%</option>
                                                <option value="14">14%</option>
                                                <option value="15">15%</option>
                                                <option value="16">16%</option>
                                                <option value="17">17%</option>
                                                <option value="18">18%</option>
                                                <option value="19">19%</option>
                                                <option value="20">20%</option>
                                                <option value="21">21%</option>
                                                <option value="22">22%</option>
                                                <option value="23">23%</option>
                                                <option value="24">24%</option>
                                                <option value="25">25%</option>
                                                <option value="26">26%</option>
                                                <option value="27">27%</option>
                                                <option value="28">28%</option>
                                            </select>
                                            {error_edit_qute?.gst_per && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit_qute.gst_per && error_edit_qute.gst_per}
                                            </span>
                                            )}
                                        </div>

                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>GST Charge</Form.Label>
                                                <Form.Control type="number" placeholder="GST Charge.." name="gst_charge" value={formDataQuote.gst_charge} readOnly/>
                                            </Form.Group>
                                           
                                            <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                                <Form.Label>Service Charge</Form.Label>
                                                <Form.Control type="number" placeholder="Service Charge.." onChange={handleChangeQute} name="service_charge" value={formDataQuote.service_charge} required/>
                                            </Form.Group>
                                        </Row>

                                    <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                    <span className='mx-2'>Update</span>
                                    {loading_update_qute && <span className='spin-sm'><Loader /></span>}
                                </Button>
                        </Form>
                    </div>
                </div>
        </div>
        {/* Edit Quotation Sidebar End */}
        
        {/* Edit Receipts Sidebar Start*/}
        <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop3" aria-labelledby="staticBackdropLabel3" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel3">Edit Receipt</h5>
                    <button type="button" ref={ref2Recipt} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <Form onSubmit={UpdateRecipt}>
                                <Row className="mb-3">
                                    <h6 className='mb-0'>General Details:</h6>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                         <hr className='w-10'/>
                                    </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control type="text" onChange={handleChangeRecipt} placeholder="Customer Name.." name="name" value={formDataReceipt.name} required/>
                                        {error_edit_receipt?.name && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error_edit_receipt.name && error_edit_receipt.name}
                                        </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="number" name="phone_number" onChange={handleChangeRecipt} placeholder="Customer Phone.." value={formDataReceipt.phone_number}  required/>

                                        {error_edit_receipt?.phone_greater && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error_edit_receipt.phone_greater && error_edit_receipt.phone_greater}
                                        </span>
                                        )}

                                        {error_edit_receipt?.phone_less && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error_edit_receipt.phone_less && error_edit_receipt.phone_less}
                                        </span>
                                        )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Recipt Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formDataReceipt.recipts_date}
                                                    onChange={handleReceiptDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Receipt Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control type="text" placeholder="Service Name.." onChange={handleChangeRecipt}
                                            value={formDataReceipt.service_name} name="service_name" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>From City/Area</Form.Label>
                                            <Form.Control type="text" onChange={handleChangeRecipt} placeholder="From.." name="from_city" value={formDataReceipt.from_city}  required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridEmail" className='mt-2'>
                                        <Form.Label>To City/Area</Form.Label>
                                        <Form.Control type="text" onChange={handleChangeRecipt} placeholder="To.." value={formDataReceipt.to_city} name="to_city" required/>
                                    </Form.Group>

                                    <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <hr className='w-10'/>
                                    </Col>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formDataReceipt.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formDataReceipt.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error_edit_receipt?.shift_end_date_final && (
                                            <span className="alert alert-danger d-block mt-1 mb-1 pt-1 pb-1">
                                                {error_edit_receipt.shift_end_date_final && error_edit_receipt.shift_end_date_final}
                                            </span>
                                            )}
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Payment Type</Form.Label>
                                        <Form.Select aria-label="Default select example" onChange={handleChangeRecipt} name="payment_type" value={formDataReceipt.payment_type}>
                                            <option value="cash">Cash</option>
                                            <option value="online">Online</option>
                                            <option value="Cheque">Cheque</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control type="number" placeholder="Total Amount.." onChange={handleChangeRecipt} value={formDataReceipt.total_amount} name="total_amount" required/>
                                    </Form.Group>

                                    <Form.Group as={Col} xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Received Amount</Form.Label>
                                        <Form.Control type="number" onChange={handleChangeRecipt} placeholder="Received Amount" name="received_amount" value={formDataReceipt.received_amount} required/>
                                        {error_edit_receipt?.received_amount && (
                                        <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error_edit_receipt.received_amount && error_edit_receipt.received_amount}
                                        </span>
                                        )}
                                    </Form.Group>
                                </Row>

                                <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                    <span className='mx-2'>Update</span>
                                    {loading_update_recipt && <span className='spin-sm'><Loader /></span>}
                                </Button>
                            </Form>
                    </div>
                </div>
            </div>
            {/* Edit Receipts Sidebar End */}
    </div>
    </>
  );
}

export default Dashboard;
