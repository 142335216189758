import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Swal from 'sweetalert2';
import photo from '../../images/company_logo.jpg'
import {useDispatch, useSelector } from 'react-redux';
import { fatchUserAPI,editUserAPI,photoUpload,fatchPhotoAPI,EditPhotoAPI} from '../../redux/actionCreation';
import { useNavigate } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import Loader from '../Loader';
function CompanyProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const ref2 = useRef(null);
    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const register_errors_edit = useSelector(state => state.EditUserReducers.error?.register_errors_edit) ?? null;
    const username = fetchUserReducres.data ? fetchUserReducres.data.username : null;
    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const expire_date = fetchUserReducres.data ? fetchUserReducres.data.expire_date : null;
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    console.log("Fatch logo",fetchcompanyLogo);
    // Accessing the 'path' property
    const logoPath = fetchcompanyLogo.data.length > 0 ? fetchcompanyLogo.data[0].path : undefined;
    console.log("Logo path", logoPath);
    const [showButton, setShowButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [profileLoad, setProfileLoad] = useState(false);
    const [loading_update, setLoading_update] = useState(false);
    const [loading_update_profile, setLoading_update_profile] = useState(false);
    // Fetch Users Data
      useEffect(() => {
        const fetchDataUser = async () => {
          setLoading_update(true);
            try {
                const res = await dispatch(fatchUserAPI());
                setLoading_update(false);
            } catch (error) {
              setLoading_update(false);
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    const handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true); 
      try {
        const res = await dispatchPhoto(photoUpload(formData));
        if(res?.type == "ADD_DATA_PHOTO_SUCCESS"){
          navigate("/update-company");
          setLoading(true); 
          await dispatchFetchPhoto(fatchPhotoAPI());
          setShowButton(false);
          setLoading(false); 
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handleEditSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append('file', file);
      try {
        setLoading(true); 
        const res = await dispatchEditPhoto(EditPhotoAPI(formData));
        if(res?.type == "FETCH_DATA_SUCCESS_PHOTO_EDIT"){
          navigate("/update-company");
          setLoading(true); 
          await dispatchFetchPhoto(fatchPhotoAPI());
          setShowButton(false);
          setLoading(false); 
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };


    useEffect(() => {
      const fetchPhoto = async () => {
        setProfileLoad(true);
          try {
            const res = await dispatchFetchPhoto(fatchPhotoAPI());
            setProfileLoad(false);
              // Process the result if needed
          } catch (error) {
            setProfileLoad(false);
              console.error('Error fetching data:', error);
          }
      };
      fetchPhoto(); // Call the async function
  }, []);

    
      // Upload Company Logo Start
    const [file, setFile] = useState(null);
    const dispatchPhoto = useDispatch();
    const dispatchFetchPhoto = useDispatch();
    const dispatchEditPhoto = useDispatch();
    const refFileClick = useRef(null);
    const [photoData, setPhotoData] = useState("");

    const handleImgClick = () => {
        refFileClick.current.click();
    }
    const handleImgChange = async(event) =>{
      setShowButton(true);
        setFile(event.target.files[0]);
        const file = event.target.files[0];
        
         // Validate file type
         const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
         if (!allowedFileTypes.includes(file.type)) {
           // Show alert for invalid file type
          //  alert('Invalid file type. Only JPG, PNG, SVG, and JPEG files are allowed.');
           // Show error SweetAlert
           await Swal.fire({
            icon: 'error',
            title: 'Invalid file type',
            text: 'Only JPG, PNG, SVG, and JPEG files are allowed..',
          });

           setShowButton(false);
           return;
         }

           // Validate file size
           const maxSizeKB = 2048; // Maximum allowed size in kilobytes (2 MB)
           if (file.size > maxSizeKB * 1024) {
               // Show error SweetAlert
               await Swal.fire({
                   icon: 'error',
                   title: 'File size exceeds limit',
                   text: `Maximum allowed file size is ${maxSizeKB} KB (2 MB).`,
               });
           
               setShowButton(false);
               return;
           }

        const imgname = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const img = new Image();
          img.src = reader.result;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const maxSize = Math.max(img.width, img.height);
            canvas.width = maxSize;
            canvas.height = maxSize;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
              img,
              (maxSize - img.width) / 2,
              (maxSize - img.height) / 2
            );
            canvas.toBlob(
              (blob) => {
                const file = new File([blob], imgname, {
                  type: "image/png",
                  lastModified: Date.now(),
                });
    
                console.log(file);
                setPhotoData(file);
              },
              "image/jpeg",
              0.8
            );
          };
        };
    }

    // Update Quotation Function
    const [formData, setFormData] = useState({
        company_name: '',
        email: '',
        phone_number: '',
        phone_number_2: '',
        company_website: '',
        company_GST: '',
        company_add: '',
        username: '',
        oldPassword: '',
        newPassword: '',
      });

      // Function to handle link click
      const UpdateCompanyClick = (companyId) => {
        setSelectedCompanyId(companyId);
        // Check if fetchUserReducres.data is defined
        if (fetchUserReducres.data) {
          // Access properties of the single company profile
          const selectedCompany = fetchUserReducres.data;
          // Update form data with the selected company's information
          setFormData({
            ...formData,
            company_name: selectedCompany.company_name,
            email: selectedCompany.email,
            phone_number: selectedCompany.phone_number,
            phone_number_2: selectedCompany.phone_number_2,
            company_website: selectedCompany.company_website,
            company_GST: selectedCompany.company_GST,
            company_add: selectedCompany.company_add,
            oldPassword: selectedCompany.oldPassword,
            newPassword: selectedCompany.newPassword,
          });

      
        } else {
          console.log("fetchUserReducres.data is undefined/null");
          // Handle this case, e.g., show an error message to the user
        }
        ref.current.click();
      };
      
    const UpdateCompany = async (event) => {
        event.preventDefault();
        setLoading_update_profile(true);
        try {
            const response = await dispatch(editUserAPI(formData, selectedCompanyId));
            setLoading_update_profile(false);
            if(response?.type == "ADD_DATA_SUCCESS_EDIT_USER"){
                ref2.current.click();
                await Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Company updated successfully.',
                    customClass: {
                        container: 'my-custom-swal', // Add your custom class here
                    },
                });
                setFormData({
                  oldPassword: '',
                  newPassword: '',
              });
               dispatch(fatchUserAPI());
            }
              else {
              // Show error SweetAlert
                await Swal.fire({
                    icon: 'error',
                    title: 'Some Error',
                    text: 'Failed to update the Company. Please try again.',
                });
            }
              
        } catch (error) {
          console.error('Error updating Company:', error);
        }
      };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    };
    const formattedDate = formatDate(expire_date);
  
  return (
    <>
      <Card>
            <Card.Body>
                <Row>
                <div xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
      <div className='image-upload-container'>
        <div className='box-decoration'>
          <h5 htmlFor='image-upload-input' className='image-upload-lab text-center'>
            {photoData ? photoData.name : "Choose an Image"}
            {loading && <Loader />}
          </h5>
          <div className='text-center'>
              {profileLoad && <Loader />}
            </div>
          {!profileLoad && (
          <div onClick={handleImgClick} className='upload_img text-center'>
            
                {
                  fetchcompanyLogo.data.length > 0 ? (
                    <div>
                      <img
                        src={`https://app.packersbilling.in/api/${fetchcompanyLogo.data[0].path}`}
                        alt={fetchcompanyLogo.data[0].originalname}
                        className='img-display-after'
                      />
                    </div>
                  ) : (
                    <img
                      src={photoData instanceof Blob ? URL.createObjectURL(photoData) : photo}
                      width="20%"
                      className='img-display-before'
                      alt="Fallback Image"
                    />
                  )
                }
            <i className='upload_icon fa fa-pencil'></i>
            <input
              ref={refFileClick}
              type='file' onChange={handleImgChange}
              className='form-control d-none' required
            />
          </div>
          )}
          {fetchcompanyLogo && fetchcompanyLogo.error && (
            <div className="error-message">{fetchcompanyLogo.error}</div>
          )}
          {fetchcompanyLogo && fetchcompanyLogo.loading ? (
            <Loader />
          ) : (
            file && showButton && (
              <button
              type="submit"
              className="btn btn-primary d-block text-center m-auto"
              onClick={fetchcompanyLogo && fetchcompanyLogo.data && fetchcompanyLogo.data[0] && fetchcompanyLogo.data[0].path ? handleEditSubmit : handleSubmit}
            >
                {fetchcompanyLogo && fetchcompanyLogo.data && fetchcompanyLogo.data[0] && fetchcompanyLogo.data[0].path ? 'Update' : 'Upload'}
              </button>
            )
          )}
        </div>
      </div>
    </div>  
                    <div className='text-center'>
                        {loading_update && <Loader />}
                    </div>
                    {!loading_update && (
                    <Row>
                      <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                      <div className='d-flex justify-content-between md-block'>
                            <p className="text-dark profile_name">Account Expired Date : </p>
                            <h6 className="text-dark profile_name_data"> <b>{format(parseISO(formattedDate), 'dd-MM-yyyy')}</b></h6>
                        </div>
                        <div className='d-flex justify-content-between md-block'>
                            <p className="text-dark profile_name">Company Name : </p>
                            <h6 className="text-dark profile_name_data md-block"> {company_name}</h6>
                        </div>
                        <div className='d-flex justify-content-between md-block'>
                            <p className="text-dark profile_name">Username : </p>
                            <h6 className="text-dark profile_name_data"> {username}</h6>
                        </div>
                        <div className='d-flex justify-content-between md-block'>
                            <p className="text-dark profile_name">Phone Number 1 : </p>
                            <h6 className="text-dark profile_name_data"> {phone_number}</h6>
                        </div>
                        <div className='d-flex justify-content-between md-block'>
                            <p className="text-dark profile_name">Phone Number 2 : </p>
                        <h6 className="text-dark profile_name_data"> {phone_number_2 ? phone_number_2 : "-"}</h6>
                        </div>
                      </Col> 
                    
                      <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                          <div className='d-flex justify-content-between md-block'>
                              <p className="text-dark profile_name">Email : </p>
                              <h6 className="text-dark profile_name_data"> {email}</h6>
                          </div>
                          <div className='d-flex justify-content-between md-block'>
                              <p className="text-dark profile_name">Company GST No. : </p>
                              <h6 className="text-dark profile_name_data"> {company_GST ? company_GST : "-"}</h6>
                          </div>
                          <div className='d-flex justify-content-between md-block'>
                              <p className="text-dark profile_name">Company Website : </p>
                              <h6 className="text-dark profile_name_data"> {company_website ? company_website : "-"}</h6>
                          </div>
                          <div className='d-flex justify-content-between md-block'>
                              <p className="text-dark profile_name">Company Address : </p>
                              <h6 className="text-dark profile_name_data"> {company_add}</h6>
                          </div>
                      </Col> 
                      <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                        <Button variant="primary" onClick={() => UpdateCompanyClick(fetchUserReducres.data.id)}>Edit Details
                        </Button>
                        <button ref={ref} class="btn btn-primary d-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                            Toggle static offcanvas
                         </button>
                      </Col>
                    </Row>
                    )}
                    
                </Row>
            </Card.Body>
            {/* Edit Company Profile Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Company Details</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                <Form onSubmit={UpdateCompany}>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.company_name} name="company_name" placeholder="Company Name.." required/>
                            {register_errors_edit?.name && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.name && register_errors_edit.name}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                            <Form.Label>Company Email</Form.Label>
                            <Form.Control type="email" onChange={handleChange} value={formData.email} name="email" placeholder="Company Email.." required/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridNumber1" className='mt-2'>
                            <Form.Label>Phone Number 1</Form.Label>
                            <Form.Control type="number" min={0} onChange={handleChange} value={formData.phone_number} name="phone_number" placeholder="Phone Number 1.." required/>
                            {register_errors_edit?.phone && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone && register_errors_edit.phone}
                                </span>
                            )}
                            {register_errors_edit?.phone_greater && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone_greater && register_errors_edit.phone_greater}
                                </span>
                            )}

                            {register_errors_edit?.phone_less && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone_less && register_errors_edit.phone_less}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridNumber2" className='mt-2'>
                            <Form.Label>Phone Number 2</Form.Label>
                            <Form.Control type="number" min={0} onChange={handleChange} value={formData.phone_number_2} name="phone_number_2" placeholder="Phone Number 2.."/>
                            
                            {register_errors_edit?.phone && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone && register_errors_edit.phone}
                                </span>
                            )}
                            {register_errors_edit?.phone2_greater && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone2_greater && register_errors_edit.phone2_greater}
                                </span>
                            )}

                            {register_errors_edit?.phone2_less && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone2_less && register_errors_edit.phone2_less}
                                </span>
                            )}
                        </Form.Group>
                        
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridWebsite" className='mt-2'>
                            <Form.Label>Company Website</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.company_website} name="company_website" placeholder="Company Website.."/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridGST" className='mt-2'>
                            <Form.Label>Company GST No.</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.company_GST} name="company_GST" placeholder="GST No..."/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                            <Form.Label>Company Address</Form.Label>
                            <Form.Control as="textarea" onChange={handleChange} value={formData.company_add} name="company_add" rows={3} placeholder="Company Address.." required/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-3'>
                            <Form.Label className='mb-0'>Change Password</Form.Label>
                            <hr class="w-10"></hr>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" sm="6" md="6" lg="6" xl="6" xxl="6" controlId="formGridCompanyName" className='mt-2'>
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.oldPassword} name="oldPassword" placeholder="Old Password..."/>
                            {register_errors_edit?.password && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1 mb-0">
                                    {register_errors_edit.password && register_errors_edit.password}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="6" sm="6" md="6" lg="6" xl="6" xxl="6" controlId="formGridCompanyName" className='mt-2 mb-2'>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.newPassword} name="newPassword" placeholder="New Password..." disabled={!formData.oldPassword}/>
                            {register_errors_edit?.newpassword && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1 mb-0">
                                    {register_errors_edit.newpassword && register_errors_edit.newpassword}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                    <span className='mx-2'>Update</span>
                                    {loading_update_profile && <span className='spin-sm'><Loader /></span>}
                                </Button>
                        </Form.Group>
                    </Row>
                </Form>
                </div>
            </div>
            {/* Edit Company Profile End */}
      </Card>
    </>
  )
}

export default CompanyProfile