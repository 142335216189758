import { combineReducers } from "redux";
import navbarReducers from "./navbarReducres";
import registrationReducres from "./registrationReducres";
import loginReducres from "./loginReducres";
import reciptsReducers from "./reciptsReducers";
import quotationReducers from "./quotationReducers";
import billReducers from "./billReducers";
import fetchRecipReducers from "./fetchRecipReducers";
import fetchBillReducers from "./fetchBillReducers";
import fetchQutReducers from "./fetchQutReducers";
import fetchUserReducres from "./fetchUserReducres";
import deleteBillReducres from "./deleteBillReducres";
import deleteQuotReducres from "./deleteQuotReducres";
import deleteRecipReducres from "./deleteRecipReducres";
import EditBillReducers from "./EditBillReducers";
import EditQuotationReducers from "./EditQuotationReducers";
import EditReciptReducrs from "./EditReciptReducrs";
import EditUserReducers from "./EditUserReducers";
import Add_bank_Reducers from "./Add_bank_Reducers";
import EditBankReducers from "./EditBankReducers";
import FetchBankReducers from "./FetchBankReducers";
import companyLogo from "./companyLogo";
import fetchcompanyLogo from "./fetchcompanyLogo";
import editCompanyLogo from "./editCompanyLogo";
import CreateSignReducers from "./CreateSignReducers";
import fetchReducers from "./fetchReducers";
import editSignReducers from "./editSignReducers";
import AddTearmsReducers from "./AddTearmsReducers";
import EditTearmsReducers from "./EditTearmsReducers";
import FeatchTearmsReducers from "./FeatchTearmsReducers";
import fetchRecipIdReducers from "./fetchRecipIdReducers";
import fetchQuotIdReducers from "./fetchQuotIdReducers";
import fetchBillIdReducers from "./fetchBillIdReducers";
import adminloginReducres from "./adminloginReducres";
import fetchAdminReducres from "./fetchAdminReducres";
import fetchAllUserReducres from "./fetchAllUserReducres";
import EditAdminUserReducers from "./EditAdminUserReducers";
const allReducrs = combineReducers({
    sidebarReducres : navbarReducers,
    registrationReducres : registrationReducres,
    loginReducres : loginReducres,
    reciptsReducers:reciptsReducers,
    quotationReducers:quotationReducers,
    billReducers:billReducers,
    fetchRecipReducers:fetchRecipReducers,
    fetchBillReducers:fetchBillReducers,
    fetchQutReducers:fetchQutReducers,
    fetchUserReducres: fetchUserReducres,
    deleteBillReducres:deleteBillReducres,
    deleteQuotReducres:deleteQuotReducres,
    deleteRecipReducres:deleteRecipReducres,
    EditBillReducers:EditBillReducers,
    EditQuotationReducers:EditQuotationReducers,
    EditReciptReducrs:EditReciptReducrs,
    EditUserReducers:EditUserReducers,
    Add_bank_Reducers:Add_bank_Reducers,
    FetchBankReducers:FetchBankReducers,
    EditBankReducers:EditBankReducers,
    companyLogo:companyLogo,
    fetchcompanyLogo:fetchcompanyLogo,
    editCompanyLogo:editCompanyLogo,
    CreateSignReducers:CreateSignReducers,
    fetchReducers:fetchReducers,
    editSignReducers:editSignReducers,
    AddTearmsReducers:AddTearmsReducers,
    EditTearmsReducers:EditTearmsReducers,
    FeatchTearmsReducers:FeatchTearmsReducers,
    fetchRecipIdReducers:fetchRecipIdReducers,
    fetchQuotIdReducers:fetchQuotIdReducers,
    fetchBillIdReducers:fetchBillIdReducers,
    adminloginReducres:adminloginReducres,
    fetchAdminReducres:fetchAdminReducres,
    fetchAllUserReducres:fetchAllUserReducres,
    EditAdminUserReducers:EditAdminUserReducers
});

export default allReducrs