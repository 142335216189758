import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Card from 'react-bootstrap/Card';
import {useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { billAPI } from '../../redux/actionCreation';
import MobileNavigation from '../MobileNavigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../Loader';

function NewBill() {
    
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const [loading, setLoading] = useState(false);
    const sidebarState = useSelector(state => state.sidebarReducres);
    const error = useSelector(state => state.billReducers.error && state.billReducers.error.create_bill_errors);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({  
        name: '',
        phone_number: '',
        bill_date: '',
        customer_address: '',
        service_name: '',
        from_city: '',
        to_city: '',
        shift_start_date: '',
        shift_end_date: '',
        shift_items: '',
        transportation_charge: '',
        packing_charge: '',
        unpacking_charge: '',
        loading_charge: '',
        unloading_charge: '',
        insurance_charge: '',
        service_charge: '',
        storage_charge: '',
        other_charge: '',
        total_charge: '',
        sgst_per: '',
        sgst_charge: '',
        cgst_per: '',
        cgst_charge: '',
        gst_per: '',
        gst_charge: '',
      });

      const resetForm = async () => {
        const confirmation = await Swal.fire({
            icon: 'question',
            title: 'Are you sure?',
            text: 'This will reset the form. Are you sure you want to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        });
    
        if (confirmation.isConfirmed) {
            setFormData({
                name: '',
                phone_number: '',
                bill_date: '',
                customer_address: '',
                service_name: '',
                from_city: '',
                to_city: '',
                shift_start_date: '',
                shift_end_date: '',
                shift_items: '',
                transportation_charge: '',
                packing_charge: '',
                unpacking_charge: '',
                loading_charge: '',
                unloading_charge: '',
                insurance_charge: '',
                service_charge: '',
                storage_charge: '',
                other_charge: '',
                total_charge: '',
                sgst_per: '',
                sgst_charge: '',
                cgst_per: '',
                cgst_charge: '',
                gst_per: '',
                gst_charge: '',
            });
        }
    };

       // Function to calculate total of specified fields
   const calculateTotal = () => {
    const {
      transportation_charge,
      packing_charge,
      unpacking_charge,
      loading_charge,
      unloading_charge,
      insurance_charge,
      storage_charge,
      other_charge,
    } = formData;

    const total =
      parseFloat(transportation_charge) +
      parseFloat(packing_charge) +
      parseFloat(unpacking_charge) +
      parseFloat(loading_charge) +
      parseFloat(unloading_charge) +
      parseFloat(insurance_charge) +
      parseFloat(storage_charge) +
      parseFloat(other_charge);
    return total;
  };

  // Function to calculate SGST charge based on total and cgst_per
  const calculateSGSTCharge = (total, sgstPercentage) => {
    return (total * parseFloat(sgstPercentage)) / 100;
  };

  // Function to calculate CGST charge based on total and cgst_per
  const calculateCGSTCharge = (total, cgstPercentage) => {
    return (total * parseFloat(cgstPercentage)) / 100;
  };
  
  // Function to calculate IGST charge based on total and gst_per
  const calculateGSTCharge = (total, gstPercentage) => {
    return (total * parseFloat(gstPercentage)) / 100;
  };

   
  
  // useEffect to watch for changes in relevant fields and recalculate charges
  useEffect(() => {
    const total = calculateTotal();
    const gstCharge = calculateGSTCharge(total, formData.gst_per);
    const cgstCharge = calculateCGSTCharge(total, formData.cgst_per);
    const sgstCharge = calculateSGSTCharge(total, formData.sgst_per);
    // Update state with the calculated values
    setFormData((prevData) => ({
      ...prevData,
      total_charge:total.toFixed(2),
      gst_charge: gstCharge.toFixed(2), // Adjust decimal places as needed
      cgst_charge: cgstCharge.toFixed(2), // Adjust decimal places as needed
      sgst_charge: sgstCharge.toFixed(2), // Adjust decimal places as needed
    }));
  }, [
    formData.transportation_charge,
    formData.packing_charge,
    formData.unpacking_charge,
    formData.loading_charge,
    formData.unloading_charge,
    formData.insurance_charge,
    formData.storage_charge,
    formData.other_charge,
    formData.sgst_per,
    formData.cgst_per,
    formData.gst_per,
  ]);

      const handleBillDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            bill_date: modifiedDate,
        }));
    };
    
      const handleStartDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_start_date: modifiedDate,
        }));
      };
    
      const handleEndDateChange = (date) => {
        const modifiedDate = new Date(date);
        modifiedDate.setHours(0, 0, 0, 0);
        // Format the date to "dd-mm-yyyy"
        const day = String(modifiedDate.getDate()).padStart(2, '0');
        const month = String(modifiedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = String(modifiedDate.getFullYear());
    
        const formattedDate = `${day}-${month}-${year}`;
        console.log("date", formattedDate);
        // Update the recipts_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
            ...prevState,
            shift_end_date: modifiedDate,
        }));
      };
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
          const res = await dispatch(billAPI(formData));
          setLoading(false);
          if(res?.type == "ADD_DATA_SUCCESS"){
            navigate("/invoiceone");
          }
          else {
            // Show error SweetAlert
              await Swal.fire({
                  icon: 'error',
                  title: 'Some Error',
                  text: 'Please check your form. Some fields are not filled in properly.',
              });
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mb-2'>
                            <h5>Create Bill</h5>
                        </Col>
                    </Row>

                        <Card className='quotaion-create'>
                            <Card.Body className='p-3'>
                            <Form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <h6 className='mb-0'>General Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                        <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>Customer Name</Form.Label>
                                            <Form.Control type="text" onChange={handleChange} placeholder="Customer Name.." name="name" value={formData.name} required/>

                                            {error?.cust_name && (
                                            <span className="alert alert-danger d-block mt-1 pt-1 mb-1 pb-1">
                                            {error.cust_name && error.cust_name}
                                            </span>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Customer Phone.." onChange={handleChange} name="phone_number" value={formData.phone_number} required />

                                            {error?.phone_greater && (
                                            <span className="alert alert-danger d-block mt-1 pt-1 mb-1 pb-1">
                                            {error.phone_greater && error.phone_greater}
                                            </span>
                                            )}

                                            {error?.phone_less && (
                                            <span className="alert alert-danger d-block mt-1 pt-1 mb-1 pb-1">
                                            {error.phone_less && error.phone_less}
                                            </span>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Bill Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.bill_date}
                                                    onChange={handleBillDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Bill Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                            <Form.Label>Customer Address</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder="Customer Address.." onChange={handleChange} name="customer_address" value={formData.customer_address} required/>
                                        </Form.Group>

                                        <h6 className='mb-0 mt-3'>Shifting Details:</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                        <Form.Group as={Col} xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control type="text" placeholder="Service Name.." onChange={handleChange} name="service_name" value={formData.service_name} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="2" lg="2" xl="2" xxl="2" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>From City/Area</Form.Label>
                                            <Form.Control type="text" placeholder="From.." onChange={handleChange} name="from_city" value={formData.from_city} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="2" lg="2" xl="2" xxl="2" controlId="formGridEmail" className='mt-2'>
                                            <Form.Label>To City/Area</Form.Label>
                                            <Form.Control type="text" placeholder="To.." onChange={handleChange} name="to_city" value={formData.to_city} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="2" lg="2" xl="2" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting Start Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_start_date}
                                                    onChange={handleStartDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Start Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="2" lg="2" xl="2" xxl="2" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Shifting End Date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.shift_end_date}
                                                    onChange={handleEndDateChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="End Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                            {error?.shift_end_date && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                            {error.shift_end_date && error.shift_end_date}
                                            </span>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                                            <Form.Label>Shifting Items</Form.Label>
                                            <Form.Control as="textarea" rows={3} placeholder="Shifting Items.." onChange={handleChange} name="shift_items" value={formData.shift_items} required/>
                                        </Form.Group>

                                        <h6 className='mb-0 mt-3'>Shifting Charges :</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Transportation Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Transportation Charge.." onChange={handleChange} name="transportation_charge" value={formData.transportation_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Packing Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Packing Charge.." onChange={handleChange} name="packing_charge" value={formData.packing_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Unpacking Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Unpacking Charge.." onChange={handleChange} name="unpacking_charge" value={formData.unpacking_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Loading Charge</Form.Label>
                                            <Form.Control type="number" min={0} onChange={handleChange} placeholder="Loading Charge.." name="loading_charge" value={formData.loading_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Unloading Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Unloading Charge.." onChange={handleChange} name="unloading_charge" value={formData.unloading_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Insurance Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Insurance Charge.." onChange={handleChange} name="insurance_charge" value={formData.insurance_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Storage Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Storage Charge.." onChange={handleChange} name="storage_charge" value={formData.storage_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Other Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Other Charge.." onChange={handleChange} name="other_charge" value={formData.other_charge} required/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Total</Form.Label>
                                            <Form.Control type="number" min={0} className='readonly-input' placeholder="Total.." name="total_charge" value={calculateTotal()} readOnly/>
                                        </Form.Group>

                                        <h6 className='mb-0 mt-3'>Tax Charges :</h6>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                            <hr className='w-10'/>
                                        </Col>

                                        <div className='form-group mt-2 col-md-3 col-lg-3  col-sm-12 col-xs-12'>
                                            <Form.Label for="sgst_per">Select SGST %</Form.Label>
                                            <select className='form-select' id="sgst_per" onChange={handleChange} name="sgst_per" value={formData.sgst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="3">3%</option>
                                                <option value="4">4%</option>
                                                <option value="5">5%</option>
                                                <option value="6">6%</option>
                                                <option value="7">7%</option>
                                                <option value="8">8%</option>
                                                <option value="9">9%</option>
                                                <option value="10">10%</option>
                                                <option value="11">11%</option>
                                                <option value="12">12%</option>
                                                <option value="13">13%</option>
                                                <option value="14">14%</option>
                                            </select>
                                            {error?.sgst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error.sgst_per && error.sgst_per}
                                            </span>
                                            )}
                                        </div>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>SGST Charge</Form.Label>
                                            <Form.Control type="number" min={0} className='readonly-input' placeholder="SGST Charge.." name="sgst_charge" value={formData.sgst_charge} readOnly/>
                                        </Form.Group>

                                        <div className='form-group mt-2 col-md-3 col-lg-3  col-sm-12 col-xs-12'>
                                            <Form.Label for="cgst_per">Select CGST %</Form.Label>
                                            <select className='form-select' id="cgst_per" onChange={handleChange} name="cgst_per" value={formData.cgst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="3">3%</option>
                                                <option value="4">4%</option>
                                                <option value="5">5%</option>
                                                <option value="6">6%</option>
                                                <option value="7">7%</option>
                                                <option value="8">8%</option>
                                                <option value="9">9%</option>
                                                <option value="10">10%</option>
                                                <option value="11">11%</option>
                                                <option value="12">12%</option>
                                                <option value="13">13%</option>
                                                <option value="14">14%</option>
                                            </select>
                                            {error?.cgst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error.cgst_per && error.cgst_per}
                                            </span>
                                            )}
                                        </div>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>CGST Charge</Form.Label>
                                            <Form.Control type="number" min={0} className='readonly-input' placeholder="CGST Charge.." name="cgst_charge" value={formData.cgst_charge} readOnly/>
                                        </Form.Group>

                                         <div className='form-group mt-2 col-md-3 col-lg-3  col-sm-12 col-xs-12'>
                                            <Form.Label for="gst_per">Select IGST %</Form.Label>
                                            <select className='form-select' id="gst_per" onChange={handleChange} name="gst_per" value={formData.gst_per} required>
                                                <option value="">Select % </option>
                                                <option value="0">0%</option>
                                                <option value="1">1%</option>
                                                <option value="2">2%</option>
                                                <option value="3">3%</option>
                                                <option value="4">4%</option>
                                                <option value="5">5%</option>
                                                <option value="6">6%</option>
                                                <option value="7">7%</option>
                                                <option value="8">8%</option>
                                                <option value="9">9%</option>
                                                <option value="10">10%</option>
                                                <option value="11">11%</option>
                                                <option value="12">12%</option>
                                                <option value="13">13%</option>
                                                <option value="14">14%</option>
                                                <option value="15">15%</option>
                                                <option value="16">16%</option>
                                                <option value="17">17%</option>
                                                <option value="18">18%</option>
                                                <option value="19">19%</option>
                                                <option value="20">20%</option>
                                                <option value="21">21%</option>
                                                <option value="22">22%</option>
                                                <option value="23">23%</option>
                                                <option value="24">24%</option>
                                                <option value="25">25%</option>
                                                <option value="26">26%</option>
                                                <option value="27">27%</option>
                                                <option value="28">28%</option>
                                            </select>
                                            {error?.gst_per && (
                                            <span className="alert alert-danger d-block mb-1 mt-1 pt-1 pb-1">
                                                {error.gst_per && error.gst_per}
                                            </span>
                                            )}
                                        </div>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>IGST Charge</Form.Label>
                                            <Form.Control type="number" min={0} className='readonly-input' placeholder="IGST Charge.." name="gst_charge" value={formData.gst_charge} readOnly/>
                                        </Form.Group>

                                        <Form.Group as={Col} xs="12" sm="12" md="3" lg="3" xl="3" xxl="3" controlId="formGridPassword" className='mt-2'>
                                            <Form.Label>Service Charge</Form.Label>
                                            <Form.Control type="number" min={0} placeholder="Service Charge.." onChange={handleChange} name="service_charge" value={formData.service_charge} required/>
                                        </Form.Group>
                                    </Row>

                                    <div className='d-flex align-items-center'>
                                        <Button variant="primary" type="submit" className='d-flex align-items-center justify-content-center'>
                                            <span className='mx-2'>Submit</span>
                                            {loading && <span className='spin-sm'><Loader /></span>}
                                        </Button>
                                        <Button variant="secondary" type="reset" onClick={resetForm} className='mx-2'>
                                            Reset
                                        </Button>
                                    </div>
                            </Form>
                            </Card.Body>
                        </Card>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default NewBill
