import React, { useEffect } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';

function SubscriptionPlans() {
  const sidebarState = useSelector(state => state.sidebarReducres);
  const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mb-1'>
                            <h4>Subscription Plans</h4>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>Starter Package</h5>
                                        <h4 className='sub-plan'>1000 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li>1 Year</li>
                                        <li>1 Businesse</li>
                                        <li>Mobile & Desktop</li>
                                        <li>Unlimited User Used</li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>Starter Package</h5>
                                        <h4 className='sub-plan'>1800 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li>2 Year</li>
                                        <li>1 Businesse</li>
                                        <li>Mobile & Desktop</li>
                                        <li>Unlimited User Used</li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" className='mt-2'>
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5>Starter Package</h5>
                                        <h4 className='sub-plan'>4000 <i className='fa fa-indian-rupee-sign'></i></h4>
                                    </div>
                                    <ul className='mt-2 mb-2'>
                                        <li>5 Year</li>
                                        <li>1 Businesse</li>
                                        <li>Mobile & Desktop</li>
                                        <li>Unlimited User Used</li>
                                    </ul>
                                    <NavLink to="tel:919624931062" className="btn btn-primary mt-2"><i className='fa fa-phone'></i> Buy For Call</NavLink>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default SubscriptionPlans
