import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';
import { useNavigate } from 'react-router-dom';

function TermsConditions() {
  const sidebarState = useSelector(state => state.sidebarReducres);
  const navigate = useNavigate();
  useEffect(()=>{
      if(!localStorage.getItem('tokan')){
          navigate('/login');
      }
  }, []);
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                            <h4>Terms Conditions</h4>
                            <h5 className="mb-3">Introduction</h5>
                                <p>
                                This Privacy Policy governs the manner in which Packers Billing collects, uses, maintains, and discloses information collected from users (each, a "User") of the Packers Billing software ("Software"). This Privacy Policy applies to the Software and all products and services offered by Packers Billing.
                                </p>

                                <h5 className="mb-3">Information We Collect</h5>
                                <p>
                                Packers Billing may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Software.
                                </p>

                                <h5 className="mb-3">How We Protect Your Information</h5>
                                <p>
                                Packers Billing adopts appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information and data stored on our Software.
                                </p>

                                <h5 className="mb-3">Sharing Your Personal Information</h5>
                                <p>
                                Packers Billing does not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
                                </p>

                                <h5 className="mb-3">Changes to This Privacy Policy</h5>
                                <p>
                                Packers Billing has the discretion to update this Privacy Policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.
                                </p>

                                <h5 className="mb-3">Your Acceptance of These Terms</h5>
                                <p>
                                By using this Software, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Software. Your continued use of the Software following the posting of changes to this policy will be deemed your acceptance of those changes.
                                </p>

                                
                                <h5 className="mb-3">Contact Us</h5>
                                <p>
                                If you have any questions about these Terms and Conditions, please contact us at [<b><a href="mailto:support@packersbilling.in">support@packersbilling.in</a></b>].
                                </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default TermsConditions
