import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';

function HelpSupport() {
  const sidebarState = useSelector(state => state.sidebarReducres);
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className='mb-3'>
                            <h4>Help & Support</h4>
                        </Col>

                        <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/JETYppMZjDc?si=6SrI-o6UKRwRCaNU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            <h5 className='text-center mt-2'>Demo Video</h5>
                        </Col>

                        <Col xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                            {/* <iframe width="100%" height="315" src="https://www.youtube.com/embed/GbNoKY6wZaw?si=0MaXcgUY9pmWR0s9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <h6 className='text-center mt-2'>How To Used Packers Bill</h6> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default HelpSupport
