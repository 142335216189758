import Dashboard from './components/Dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UpdateCompany from './components/UpdateCompany';
import HelpSupport from './components/HelpSupport';
import SubscriptionPlans from './components/SubscriptionPlans';
import ListBills from './components/bills/ListBills';
import NewBill from './components/bills/NewBill';
import CreateReceipts from './components/receipts/CreateReceipts';
import ReceiptsList from './components/receipts/ReceiptsList';
import QuotationList from './components/quotation/QuotationList';
import CretaeQuotation from './components/quotation/CretaeQuotation';
import Aboutus from './components/Aboutus';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import Login from './components/authentication/Login';
import Register from './components/authentication/Register';
import Header from './components/Header.js';
import Sidebar from './components/Sidebar.js';
import Tempone from './components/billDesign/Tempone.js';
import './App.css';
import './css/style.css'
import './css/responsive.css'
import './js/style.js'
import Quotedesign from './components/quotDesign/Quotedesign.js';
import Reciptdesign from './components/reciptDesign/Reciptdesign.js';
import ReciptEditdesign from './components/reciptDesign/ReciptEditdesign.js';
import QuoteEditdesign from './components/quotDesign/QuoteEditdesign.js';
import TempEditone from './components/billDesign/TempEditone.js';
import AdminLogin from './components/Admin/AdminLogin.js';
import Alluser from './components/Admin/Alluser.js';
import AdminHeader from './components/Admin/AdminHeader.js';
import Notfound from './components/Notfound.js';
import OtherService from './components/OtherService.js';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route
            path="/"
            element={
              <>
                <Sidebar />
                <Header />
                <Dashboard />
              </>
            }
          />
        <Route
            path="all-user"
            element={
              <>
               <AdminHeader/>
               <Alluser/>
              </>
            }
          />

        <Route
            path="quotationList-list"
            element={
              <>
                <Sidebar />
                <Header />
                <QuotationList/>
              </>
            }
          />
    
          <Route path='create-quotation' element={
              <>
                <Sidebar />
                <Header />
                <CretaeQuotation/>
              </>
            }/>

          <Route path='receipts-list' element={
            <>
            <Sidebar />
            <Header />
            <ReceiptsList/>
            </>
          }/>

          <Route path='create-receipts' element={
            <>
            <Sidebar />
            <Header />
            <CreateReceipts/>
            </>
          }/>

          <Route path='list-bills' element={
            <>
            <Sidebar />
            <Header />
            <ListBills/>
            </>
          }/>

          <Route path='create-bills' element={
            <>
            <Sidebar />
            <Header />
            <NewBill/>
            </>
          }/>

          <Route path='aboutus' element={
            <>
            <Sidebar />
            <Header />
            <Aboutus/>
            </>
          }/>

          <Route path='privacy-policy' element={
            <>
            <Sidebar />
            <Header />
            <PrivacyPolicy/>
            </>
          }/>

          <Route path='terms-conditions' element={
            <>
            <Sidebar />
            <Header />
            <TermsConditions/>
            </>
          }/>

          <Route path='update-company' element={
            <>
            <Sidebar />
            <Header />
            <UpdateCompany/>
            </>
          }/>

          <Route path='help-support' element={
            <>
            <Sidebar />
            <Header />
            <HelpSupport/>
            </>
          }/>
          <Route path='subscription-plans' element={
            <>
            <Sidebar />
            <Header />
            <SubscriptionPlans/>
            </>
          }/>

          <Route path='other-service' element={
            <>
            <Sidebar />
            <Header />
            <OtherService/>
            </>
          }/>

          <Route path='invoiceone' element={
            <>
              <Tempone/>
            </>
          }/>

          <Route path='invoiceedit/:id' element={
            <>
              <TempEditone/>
            </>
          }/> 

          <Route path='quoteone' element={
            <>
              <Quotedesign/>
            </>
          }/>

          <Route path='recipteone' element={
            <>
              <Reciptdesign/>
            </>
          }/>

        <Route path='recipteedit/:id' element={
            <>
              <ReciptEditdesign/>
            </>
          }/>

          <Route path='quotationedit/:id' element={
            <>
              <QuoteEditdesign/>
            </>
          }/>

          <Route path='billedit/:id' element={
            <>
              <TempEditone/>
            </>
          }/>

          <Route path='login' element={
            <Login/>
          }/>

          <Route path='register' element={
            <Register/>
          }/>

          <Route path='admin-greyline' element={
            <AdminLogin/>
          }/>

          <Route path='all-user' element={
            <Alluser/>
          }/>

          <Route path='*' element={
            <Notfound/>
          }/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
