import {NavLink,useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { navbarAction} from "../redux/actionCreation";
function Sidebar() {
    const [quotationstate, SetQuotation] = useState(false);
    const [receiptstate, SetReceipt] = useState(false);
    const [billstate, SetBillstate] = useState(false);
    const [aboutstate, SetAboutstate] = useState(false);

    const location = useLocation();
        useEffect(
            () => {
            // Logic to check if the current location matches any of the links
                if (location.pathname === '/quotationList-list' || location.pathname === '/create-quotation') {
                    SetQuotation(true);
                } else {
                    SetQuotation(false);
                }
            },[location.pathname]);
            
        useEffect(
            () => {
                // Logic to check if the current location matches any of the links
                if (location.pathname === '/list-bills' || location.pathname === '/create-bills') {
                    SetBillstate(true);
                } else {
                    SetBillstate(false);
                }
            },[location.pathname]);

        useEffect(
            () => {
                 // Logic to check if the current location matches any of the links
                if (location.pathname === '/receipts-list' || location.pathname === '/create-receipts') {
                    SetReceipt(true);
                } else {
                     SetReceipt(false);
                }
            },[location.pathname]);

        useEffect(
            () => {
            // Logic to check if the current location matches any of the links
                if (location.pathname === '/aboutus' || location.pathname === '/privacy-policy' || location.pathname === '/terms-conditions') {
                    SetAboutstate(true);
                    } else {
                        SetAboutstate(false);
                    }   
                },[location.pathname]);

    const dispatch = useDispatch();         
    const sidebarState = useSelector(state => state.sidebarReducres);
    const hideSidebar = () => {
        if (sidebarState) {
          // Only dispatch the action if the sidebar is currently hidden
          dispatch(navbarAction());
        }
      };
      const handleLogout = () => {
        hideSidebar(); // Call the hideSidebar function
        logoutFun();  // Call the logoutFun function
      };
      
    const logoutFun = () =>{
        localStorage.removeItem('tokan');
      };
   
  return (
    <>
      <div className={`sidebar ${sidebarState ? 'sidebar_hide' : ''}`}>
        <div className='menu'>
            <ul className='sidebar-links'>
                <li>
                    <NavLink to="/" onClick={hideSidebar}> <i className="fa fa-home home-icon"></i> <span className='link-text'>Dashboard</span></NavLink>
                </li>

                <li>
                    <div className={`${quotationstate ? 'active-link' : 'unactive-link'}`}>
                        <button type="button" class="accordion-button" onClick={() => SetQuotation(!quotationstate)}><i class="fa fa-file"></i> <span class="link-text"> Quotation</span> <i class="fa fa-circle-chevron-down"></i>
                        </button>
                    </div>
                    {quotationstate && (
                        <ul className={`sub-menu ${quotationstate ? 'sub-menu2' : ''}`}>
                            <li><NavLink to="/quotationList-list" onClick={hideSidebar}>Quotation List</NavLink></li>
                            <li><NavLink to="/create-quotation" onClick={hideSidebar}>Create Quotation</NavLink></li>
                        </ul>
                    )}
                </li>

                <li>
                    <div className={`${receiptstate ? 'active-link' : 'unactive-link'}`}>
                        <button type="button" class="accordion-button" onClick={() => SetReceipt(!receiptstate)}><i class="fa fa-receipt"></i> <span class="link-text">Receipt</span> <i class="fa fa-circle-chevron-down"></i>
                        </button>
                    </div>
                    {receiptstate && (
                        <ul className={`sub-menu ${receiptstate ? 'sub-menu2' : ''}`}>
                            <li><NavLink to="/receipts-list" onClick={hideSidebar}>Receipts List</NavLink></li>
                            <li><NavLink to="/create-receipts" onClick={hideSidebar}>Create Receipts</NavLink></li>
                        </ul>
                    )}
                </li>

                <li>
                    <div className={`${billstate ? 'active-link' : 'unactive-link'}`}>
                        <button type="button" class="accordion-button" onClick={() => SetBillstate(!billstate)}><i class="fa fa-file-invoice"></i> <span class="link-text">Bills/Invoice</span> <i class="fa fa-circle-chevron-down"></i>
                        </button>
                    </div>
                    {billstate && (
                        <ul className={`sub-menu ${billstate ? 'sub-menu2' : ''}`}>
                            <li><NavLink to="/list-bills" onClick={hideSidebar}>Bills/Invoice List</NavLink></li>
                            <li><NavLink to="/create-bills" onClick={hideSidebar}>Create Bill/Invoice</NavLink></li>
                        </ul>
                    )}
                </li>

                <li>
                    <div className={`${aboutstate ? 'active-link' : 'unactive-link'}`}>
                        <button type="button" class="accordion-button" onClick={() => SetAboutstate(!aboutstate)}><i class="fa fa-building"></i> <span class="link-text">About Us</span> <i class="fa fa-circle-chevron-down"></i>
                        </button>
                    </div>
                    {aboutstate && (
                        <ul className={`sub-menu ${aboutstate ? 'sub-menu-about' : ''}`}>
                            <li><NavLink to="/aboutus" onClick={hideSidebar}>About Packers Bill</NavLink></li>
                            <li><NavLink to="https://packersbilling.in/privacy-policy.php" target='_blank' onClick={hideSidebar}>Privacy Policy</NavLink></li>
                            <li><NavLink to="https://packersbilling.in/terms-and-conditions.php" target='_blank' onClick={hideSidebar}>Terms & Conditions</NavLink></li>
                        </ul>
                    )}
                </li>
                
                <li><NavLink to="/update-company" onClick={hideSidebar}> <i className="fa fa-building"></i> <span className='link-text'> Edit Company Details</span></NavLink></li>

                <li><NavLink to="/help-support" onClick={hideSidebar}> <i className="fa fa-handshake"></i> <span className='link-text'> Help & Support</span></NavLink></li>
    
                <li><NavLink to="/subscription-plans" onClick={hideSidebar}> <i className="fa fa-indian-rupee-sign"></i> <span className='link-text'> Subscription Plans</span></NavLink></li>

                <li><NavLink to="/other-service" onClick={hideSidebar}> <i class="fa-solid fa-angles-right"></i> <span className='link-text'> Other Service</span></NavLink></li>
                    
                <li><NavLink  to="/login" onClick={handleLogout}> <i className="fa fa-sign-out"></i> <span className='link-text'> Logout</span></NavLink></li>
            </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
