import React, { useEffect, useRef, useState } from 'react';
import '../../css/billstyle.css'
import {useDispatch, useSelector } from 'react-redux';
import { fatchBillAPI,fatchSignAPI,fatchUserAPI,fatchPhotoAPI,fatchBillIDAPI,fatchTearmsAPI,fatchBankAPI} from '../../redux/actionCreation';
import { parseISO, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import html2pdf from 'html2pdf.js';
import { Row } from 'react-bootstrap';
import Loader from '../Loader';

function TempEditone() {
    //view port set
    useEffect(() => {
        // Update the viewport meta tag when ABCComponent mounts
        const metaTag = document.querySelector('meta[name="viewport"]');
        if (metaTag) {
          metaTag.setAttribute('content', 'width=device-width, initial-scale=0.4, maximum-scale=1.0, user-scalable=yes');
        }
    
        // Clean up function to reset the viewport when ABCComponent unmounts
        return () => {
          if (metaTag) {
            metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
          }
        };
      }, []); // Run only once on component mount
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const dispatchFetchPhoto = useDispatch();
    const fetchBillIdReducers = useSelector(state => state.fetchBillIdReducers);
    const BillData = fetchBillIdReducers.data;
    const signatures = useSelector((state) => state.fetchReducers);
    const hasSignature = signatures.data.some(signature => signature.signature_data);
    const fetchcompanyLogo = useSelector(state => state.fetchcompanyLogo);
    const FetchBankReducers = useSelector(state => state.FetchBankReducers);
    const error_edit = useSelector(state => state.EditBillReducers.error?.edit_bill_errors) ?? null;

    const fetchUserReducres = useSelector(state => state.fetchUserReducres);
    const email = fetchUserReducres.data ? fetchUserReducres.data.email : null;
    const company_name = fetchUserReducres.data ? fetchUserReducres.data.company_name : null;
    const phone_number = fetchUserReducres.data ? fetchUserReducres.data.phone_number : null;
    const phone_number_2 = fetchUserReducres.data ? fetchUserReducres.data.phone_number_2 : null;
    const company_website = fetchUserReducres.data ? fetchUserReducres.data.company_website : null;
    const company_GST = fetchUserReducres.data ? fetchUserReducres.data.company_GST : null;
    const company_add = fetchUserReducres.data ? fetchUserReducres.data.company_add : null;
    const FeatchTearmsReducers = useSelector(state => state.FeatchTearmsReducers);

   

    useEffect(() => {
        const fetchDataUser = async () => {
            try {
                const res = await dispatch(fatchUserAPI());
            } catch (error) {
                console.error('Error fetching Company Details data:', error);
            }
        };
        fetchDataUser();
    }, []);

    const { id } = useParams();
    useEffect(() => {
        const fetchDataBill = async () => {
            setLoading(true);
        try {
            const res = await dispatch(fatchBillIDAPI(id));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
        };
        fetchDataBill(); // Call the async function
    }, [id]); // Include 'id' in the dependency array

    useEffect(() => {
        const fetchDataSign = async () => {
            try {
                const res = await dispatch(fatchSignAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataSign(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
              const res = await dispatchFetchPhoto(fatchPhotoAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPhoto(); // Call the async function
    }, []);

  useEffect(() => {
        const fetchDataTearms = async () => {
            try {
                const res = await dispatch(fatchTearmsAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataTearms(); // Call the async function
    }, []);

    useEffect(() => {
        const fetchBank = async () => {
            try {
                const res = await dispatch(fatchBankAPI());
                // Process the result if needed
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchBank(); // Call the async function
    }, []);

    const imageUrl = `https://app.packersbilling.in/api/${fetchcompanyLogo.data.path}`;
    const [base64URL, setBase64URL] = useState('');
    useEffect(() => {
        const fetchImage2 = async () => {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
            throw new Error(`Failed to fetch image. Status: ${response.status}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
            const base64 = reader.result;
            setBase64URL(base64);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching or converting the image:', error);
        }
        };
  
        fetchImage2();
    }, [imageUrl]);
  
    const handleDownloadPdf = () => {
        const element = document.getElementById('content-to-pdf');
        html2pdf(element, {
          margin: 4,
          filename: 'invoice.pdf',
          html2canvas: {
            scale: 2,
            useCORS: true, // Add this option to handle cross-origin requests
          },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
        });
      };
  return (
    <>
    {loading && <div className='text-center mt-4'>
        <Loader />
    </div>}
    {!loading && (
    <Row>
        {BillData && typeof BillData === 'object' && (
        <div className="container-fluid invoice-container">
            <div className="table-responsive" id='content-to-pdf'>
                <table className="table table-bordered border mb-0">
                    <tbody>
                        <tr>
                        <td colspan="2" className="bg-light text-center">
                            <h3 className="mb-0">
                                {company_name}
                            </h3></td>
                        </tr>
                        <tr>
                            <td colspan="2" className="text-center text-lowercase">
                                <span><i class="fa fa-map-marker-alt"></i> {company_add}</span><br/>
                                <span className='email'><i class="fa fa-envelope"></i> {email}
                                {company_website && ( // Check if company_GST exists
                                    <span className='text-lowercase'> <i class="fa fa-globe-americas"></i> {company_website}</span>
                                )}
                                </span>
                            </td>
                        </tr>
                        <tr className='bg-light v-center'>
                            <td colspan="2" className="py-1">
                                <div className="row">
                                    {company_GST && ( // Check if company_GST exists
                                        <div className="col">
                                            <strong><i className="fa fa-file"></i> GSTIN No : {company_GST}</strong>
                                        </div>
                                    )}
                                
                                    <div className="col text-center fw-600 text-3 text-uppercase">Tax Invoice</div>
                                    <div className="col text-end">
                                        <strong><i class="fa fa-phone"></i>
                                            <span> {phone_number}</span>
                                            {phone_number_2 && ( // Check if company_GST exists
                                            <span>, {phone_number_2}</span>
                                            )}
                                        </strong>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                        <td className="col-8">
                            <div className="row gx-2 gy-2">
                                <div className="col-6"><strong>Customer Name : </strong> 
                                <p>{BillData.name}</p>
                                </div>

                                <div className="col-3"><strong>From : </strong> 
                                <p>{BillData.from_city}</p>
                                </div>

                                <div className="col-3"><strong>To : </strong> 
                                <p>{BillData.to_city}</p>
                                </div>
                                <div className="col-12 mt-0"><strong>Customer Address  : </strong> {BillData.customer_address}</div>

                                <div className="col-12 mt-2"><strong>Shifting Items : </strong> 
                                    {BillData.shift_items}
                                </div>
                            </div>
                        </td>
                        <td className="col-4 bg-light">
                            <div className="row gx-2 gy-1 fw-600">
                                <div className="col-6">Invoice No <span className="float-end">:</span></div>
                                <div className="col-6">{BillData.invoice_number}</div>
                                                        
                                <div className="col-6">Invoice Date <span className="float-end">:</span></div>
                                <div className="col-6">
                                    {(() => {
                                        try {
                                        return format(parseISO(BillData.bill_date), 'dd-MM-yyyy');
                                        } catch (error) {
                                        console.error('Error parsing date:', BillData.bill_date);
                                        return 'Invalid Date';
                                        }
                                    })()}
                                </div>

                                <div className="col-6">Shift Start Date <span className="float-end">:</span></div>
                                <div className="col-6">
                                    {(() => {
                                        try {
                                        return format(parseISO(BillData.shift_start_date), 'dd-MM-yyyy');
                                        } catch (error) {
                                        console.error('Error parsing date:', BillData.shift_start_date);
                                        return 'Invalid Date';
                                        }
                                    })()}
                                </div>

                                <div className="col-6">Shift End Date <span className="float-end">:</span></div>
                                <div className="col-6">
                                    {(() => {
                                        try {
                                        return format(parseISO(BillData.shift_end_date), 'dd-MM-yyyy');
                                        } catch (error) {
                                        console.error('Error parsing date:', BillData.shift_end_date);
                                        return 'Invalid Date';
                                        }
                                    })()}
                                </div>

                                <div className="col-4">Service <span className="float-end">:</span></div>
                                <div className="col-8">{BillData.service_name}</div>

                                <div className="col-4">Contact <span className="float-end">:</span></div>
                                <div className="col-8">{BillData.phone_number}</div>
                                
                            </div>
                        </td>
                        </tr>
                        <tr>
                            <td colspan="2" className="p-0">
                                <table class="table text-1 mb-0">
                                    <tbody>
                                        <tr class="bg-light">
                                            <td colspan="6" class="pt-2 pb-2 pr-1 pl-2 text-center"><strong>Shifting Charges</strong></td>
                                        </tr>
                                        <tr>
                                            <td><strong>Transportation Charge</strong></td>
                                            <td>{BillData.transportation_charge}</td>
                                            <td><strong>Packing Charge</strong></td>
                                            <td>{BillData.packing_charge}</td>
                                            <td><strong>Unpacking Charge</strong></td>
                                            <td>{BillData.unpacking_charge}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Loading Charge</strong></td>
                                            <td>{BillData.loading_charge}</td>
                                            <td><strong>Unloading Charge</strong></td>
                                            <td>{BillData.unloading_charge}</td>
                                            <td><strong>Insurance Charge</strong></td>
                                            <td>{BillData.insurance_charge}</td>
                                        </tr>
                                        <tr className='b-none'>
                                            {/* <td><strong>Service Charge</strong></td>
                                            <td>{BillData.service_charge}</td> */}
                                            <td><strong>Storage Charge</strong></td>
                                            <td>{BillData.storage_charge}</td>
                                            <td><strong>Other Charge</strong></td>
                                            <td>{BillData.other_charge}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="bg-light fw-600 border-style">
                            <td className="col-7 text-1" rowSpan={4} style={{ backgroundColor: '#fff !important' }}>
                                <h4 className="text-3 mt-1 mb-1">Bank Details:</h4>
                                {FetchBankReducers && Array.isArray(FetchBankReducers.data) && FetchBankReducers.data.length > 0 ? (
                                [...FetchBankReducers.data].reverse().map(item => (
                                <div key={item.id} class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-0">
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <p class="text-dark profile_name">Account Holder Name :</p> <h6 class="text-dark profile_name_data mx-2">{item.account_holder_name}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">Bank Name :</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.bank_name}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">Account Number:</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.account_number}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="text-dark profile_name">IFFC Code :</p>
                                        <h6 class="text-dark profile_name_data mx-2">{item.iffc_code}</h6>
                                    </div>
                                </div>
                                ))
                                ) : (
                                    <div className='text-left not_data mt-3 mb-3'>
                                        <Link to="/update-company" className='btn btn-primary'>Add Bank Details</Link>
                                    </div>
                                )} 
                                {/* <span className="fw-600"></span> */}
                                
                                <h4 className="text-3 mt-0">Terms and conditions:</h4>
                                {FeatchTearmsReducers && Array.isArray(FeatchTearmsReducers.data) && FeatchTearmsReducers.data.length > 0 ? (
                                [...FeatchTearmsReducers.data].reverse().map(item => (
                                <h6 className="text-dark profile_name_data_terms printpage mb-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.tearms) }} />
                            ))
                            ) : (
                                <div className='text-center not_data mt-3'>
                                <Link to="/update-company" className='d-print-none btn btn-primary'>Add Tearms+</Link>
                                </div>
                                )} 
                                
                            </td>
                            <td className="col-5 py-1 pe-1 v-center">Sub Total <span className="float-end"><i className='fa fa-indian-rupee'></i> {BillData.total}</span></td>
                        </tr>
                        <tr className='border-style'>
                            <td className="col-5 pe-1">

                                <strong>SGST Tax :</strong> <small>({BillData.sgst_per}%)</small> <span className="float-end"><i className='fa fa-indian-rupee'></i> {BillData.sgst_charge}</span> <br />

                                <strong>CGST Tax :</strong> <small>({BillData.cgst_per}%)</small> <span className="float-end"><i className='fa fa-indian-rupee'></i> {BillData.cgst_charge}</span><br />

                                <strong>IGST :</strong> <small>({BillData.gst_per}%)</small> <span className="float-end"><i className='fa fa-indian-rupee'></i> {BillData.gst_charge}</span><br/>

                                <strong>Service Tax :</strong> <span className="float-end"><i className='fa fa-indian-rupee'></i> {BillData.service_charge}</span><br/>
                            </td>
                        </tr>
                        <tr className='border-style'>
                            <td className="col-5 pe-1 bg-light fw-600 v-center">
                            Grand Total<span className="float-end"><i className='fa fa-indian-rupee'></i> {BillData.total_amount}</span>
                            </td>
                        </tr>
                        <tr className='border-style'>
                            <td className="col-5 pe-1 text-end">
                                <span className='text-blue stemp d-block text-end'>For, {company_name}</span>
                                {[...signatures.data].map((signature, index) => (
                                    <img key={index} src={signature.signature_data} alt={`Signature ${index + 1}`} className='sign-size' />
                                ))}
                                {!hasSignature && (
                                    <Link to="/update-company" className='d-print-none btn btn-primary'>
                                        Add Sign
                                    </Link>
                                )}
                                <div className="text-1 fst-italic mt-0">
                                    <span className='text-blue stemp'>(Authorised Signatory)</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span className='mb-0 mt-2 d-block text-center'><strong>NOTE :</strong> This is computer generated invoice and does not require physical signature.</span>
            </div>
            <footer className="text-center mt-4 invoice_design">
                <div className="btn-group btn-group-sm d-print-none">
                    <a href="javascript:window.print()" className="btn btn-info quot_btn pt-2 pb-2"><i className="fa fa-print"></i> Print & Download</a> 
                </div>
                
                <button
                onClick={handleDownloadPdf}
                type="button"
                className='btn btn-info bill_btn pt-2 pb-2 d-print-none'>
                    Download as PDF
                </button>
                
                <Link class="btn btn-info plus_btn text-white pt-2 pb-2 d-print-none" to="/list-bills"><i className="fa fa-file"></i> All Bill</Link>
            </footer>
        </div>
        )}
    </Row>
    )}
    </>
  )
}

export default TempEditone
