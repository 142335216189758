import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import FixedButton from './FixedButton';
import MobileNavigation from './MobileNavigation';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy() {
  const sidebarState = useSelector(state => state.sidebarReducres);
  const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('tokan')){
            navigate('/login');
        }
    }, []);
  return (
    <>
        <div className={`main-content ${!sidebarState ? '' : 'navbar-ful'}`}>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                            <h4>Privacy Policy</h4>
                            <p>With the help of Grayline Bill & Quotation you can generate Quotation and Bill for your Packers & Movers clients. Now if you want to send quotation to your customer then how do you send it? Either you send it by writing it on paper or you have some quotation template used in it inside the computer. No backup your quotations / bills And you may not even have a computer available at all times. Much difficulty in sending bills or sending quotations in this way</p>

                            <p>So to solve such a problem, he has come up with a grayline. Grayline Quotations and Bills With this application, you will be able to create quotations of Bill for your clients very easily.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <MobileNavigation/>
        </div>
    </>
  )
}

export default PrivacyPolicy
