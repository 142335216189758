import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import {useDispatch, useSelector } from 'react-redux';
import { parseISO, format, getMonth, isSameMonth } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fatchAllUserAPI,fatchUserAPI,editallUserAPI} from '../../redux/actionCreation';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { FormCheck } from 'react-bootstrap';

function Alluser() {
    const navigate = useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem('admin-tokan')){
            navigate('/admin-greyline');
        }
    }, []);

      /* 1. Bill Fetching Data Start*/
      const fetchUserReducres = useSelector(state => state.fetchUserReducres);
      const fetchAllUserReducres = useSelector((state) => state.fetchAllUserReducres);
      const totalBill = fetchAllUserReducres.data ? fetchAllUserReducres.data.length : 0;
      const currentMonthBills = fetchAllUserReducres?.data?.filter(item => isSameMonth(parseISO(item.registration_date), new Date())) || [];
      const totalBillMonth = currentMonthBills.length;
      const [selectedBillId, setSelectedBillId] = useState(null);
      const error_edit = useSelector(state => state.EditBillReducers.error?.edit_bill_errors) ?? null;
      const [selectedCompanyId, setSelectedCompanyId] = useState(null);
      const ref = useRef(null);
      const ref2 = useRef(null);
      const register_errors_edit = useSelector(state => state.EditAdminUserReducers.error?.register_errors_edit) ?? null;
      const dispatch = useDispatch();
      let expiredUserCount = 0;
      {fetchAllUserReducres && Array.isArray(fetchAllUserReducres.data) && fetchAllUserReducres.data.length > 0 && fetchAllUserReducres.data.forEach(item => {
        const currentDate = new Date();
        const expireDate = parseISO(item.expire_date);
      
        if (expireDate < currentDate) {
          expiredUserCount++;
        }
      })}

      /*1. Bill Fetching Data End*/
    
          // Fetch Receipts Function Start
          useEffect(() => {
            const fetchData = async () => {
                try {
                    const res = await dispatch(fatchAllUserAPI());
                    // Process the result if needed
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData(); // Call the async function
        }, []);
        // Fetch Receipts Function End

        // Update Quotation Function
          const [formData, setFormData] = useState({
            company_name: '',
            email: '',
            phone_number: '',
            phone_number_2: '',
            company_website: '',
            company_GST: '',
            company_add: '',
            username: '',
            password: '',
            expire_date: '',
          });

          const formatDate = (dateString) => {
            const date = new Date(dateString);
            const formattedDate = date.toISOString().split('T')[0];
            return formattedDate;
        };

       // Function to handle link click
       const UpdateUserClick = (userId) => {
        setSelectedCompanyId(userId);
        // Check if fetchDataRecipst.data is available and not empty
        if (fetchAllUserReducres.data && fetchAllUserReducres.data.length > 0) {
            const selectedCompany = fetchAllUserReducres.data.find(user => user.id === userId);

            if (selectedCompany) {
                const expire_date = formatDate(selectedCompany.expire_date);
                const defaultTime = new Date();
                defaultTime.setHours(0, 0, 0, 0);
    
                setFormData({
                  ...formData,
                    company_name: selectedCompany.company_name,
                    email: selectedCompany.email,
                    phone_number: selectedCompany.phone_number,
                    phone_number_2: selectedCompany.phone_number_2,
                    company_website: selectedCompany.company_website,
                    company_GST: selectedCompany.company_GST,
                    company_add: selectedCompany.company_add,
                    username: selectedCompany.username,
                    expire_date: new Date(expire_date + 'T00:00:00'),
                });
            } else {
                console.log("Receipt not found for ID:", userId);
            }
        } else {
            console.log("No receipt data available");
        }
    };

    
  const UpdateCompany = async (event) => {
      event.preventDefault();
      try {
          const response = await dispatch(editallUserAPI(formData, selectedCompanyId));
          if(response?.type == "ADD_DATA_SUCCESS_EDIT_ADMINUSER"){
              ref2.current.click();
              await Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Company updated successfully.',
                  customClass: {
                      container: 'my-custom-swal', // Add your custom class here
                  },
              });
              setFormData({
                password: ''
              });
             dispatch(fatchAllUserAPI());
          }
            else {
            // Show error SweetAlert
              await Swal.fire({
                  icon: 'error',
                  title: 'Some Error',
                  text: 'Failed to update the Company. Please try again.',
              });
          }
            
      } catch (error) {
        console.error('Error updating Company:', error);
      }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      };
    
      const handleExptChange = (date) => {
        // Ensure date is not null or undefined
        if (!date) {
          console.error("Invalid date");
          return;
        }
    
        // Update the expire_date field in formData when the DatePicker changes
        setFormData((prevState) => ({
          ...prevState,
          expire_date: date,
        }));
      };
    
      // Function to handle form submission
      const handleSubmit = (event) => {
        event.preventDefault();
        // Code to submit formData
        console.log("Form data submitted:", formData);
      };
  return (
    <>
      <div className='page-content'>
            <Container fluid>
                <Row>
                    <Col xs="6" sm="6" md="4" lg="4" xl="4" xxl="4" className='d-flex'>
                        <div className="dash-count">
                            <div className="dash-counts">
                                <h4>{totalBill}</h4>
                                <h5>Total User</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-user-group"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="4" lg="43" xl="4" xxl="4" className='d-flex'>
                        <div className="dash-count das1">
                            <div className="dash-counts">
                                <h4>{totalBillMonth}</h4>
                                <h5>This Month Invoice</h5>
                            </div>
                            <div className="dash-imgs">
                                <i className="fa fa-user"></i>
                            </div>
                        </div>
                    </Col>

                    <Col xs="6" sm="6" md="4" lg="4" xl="4" xxl="4" className='d-flex'>
                        <div className="dash-count das2">
                            <div className="dash-counts">
                                <h4>{expiredUserCount}</h4>
                                <h5>Expired Package</h5>
                            </div>
                            <div className="dash-imgs">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                  {/* Recent Bill */}
                  <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                        <h6 className='mt-3'>All Users </h6>
                            <Row>
                                {fetchAllUserReducres && Array.isArray(fetchAllUserReducres.data) && fetchAllUserReducres.data.length > 0 ? (
                                fetchAllUserReducres.data.slice().reverse().map(item => (
                                    <Col key={item.id} xs="12" sm="12" md="6" lg="4" xl="4" xxl="4">
                                    <div className="dash-count bill_box das3">
                                        <div className="dash-counts">
                                            <h4 className="text-dark c_name">{item.name}</h4>
                                            <h5 className="text-dark text-green">Company : {item.company_name}</h5>
                                            <div className=''>
                                                {item.registration_date && (
                                                    <h5 className="text-dark">
                                                    Register Date {format(parseISO(item.registration_date), 'dd-MM-yyyy')}
                                                    </h5>
                                                )}
                                                {item.expire_date && (
                                                    <h5 className="text-dark">
                                                    Exp Date {format(parseISO(item.expire_date), 'dd-MM-yyyy')}
                                                    </h5>
                                                )}
                                            </div>
                                        </div>
                                        <div className="dash-imgs">
                                                <p>
                                                    <Link onClick={() => UpdateUserClick(item.id)}
                                                    type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                                        <i className='view_invoice_i pr-3 fa fa-edit'></i>
                                                    </Link>
                                                </p>
                                        </div>
                                    </div>
                                    </Col>
                                    ))
                                    ) : (
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                                        <div className='text-center not_data bill_box'>
                                            <i class="fa-solid fa-triangle-exclamation d-block text-center"></i>
                                            <p className='text-center'>No data available</p>
                                        </div>
                                    </Col>
                                    )}
                            </Row>
                    </Col>
                    {/* Recent Bill */}
                </Row>
            </Container>

            {/* Edit User Profile Sidebar Start*/}
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabIndex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel" style={{ zIndex: 99999999999 }}>
                <div class="offcanvas-header bg-info">
                    <h5 class="offcanvas-title text-white" id="staticBackdropLabel">Edit Company Details</h5>
                    <button type="button" ref={ref2} class="btn-close btn-close2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                <Form onSubmit={UpdateCompany}>
                    <Row className="mb-3">
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2'>
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.company_name} name="company_name" placeholder="Company Name.." required/>
                            {register_errors_edit?.name && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.name && register_errors_edit.name}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridEmail" className='mt-2'>
                            <Form.Label>Company Email</Form.Label>
                            <Form.Control type="email" onChange={handleChange} value={formData.email} name="email" placeholder="Company Email.."/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridNumber1" className='mt-2'>
                            <Form.Label>Phone Number 1</Form.Label>
                            <Form.Control type="number" onChange={handleChange} value={formData.phone_number} name="phone_number" placeholder="Phone Number 1.." required/>
                            {register_errors_edit?.phone && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone && register_errors_edit.phone}
                                </span>
                            )}
                            {register_errors_edit?.phone_greater && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone_greater && register_errors_edit.phone_greater}
                                </span>
                            )}

                            {register_errors_edit?.phone_less && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone_less && register_errors_edit.phone_less}
                                </span>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridNumber2" className='mt-2'>
                            <Form.Label>Phone Number 2</Form.Label>
                            <Form.Control type="number" onChange={handleChange} value={formData.phone_number_2} name="phone_number_2" placeholder="Phone Number 2.."/>
                            
                            {register_errors_edit?.phone && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone && register_errors_edit.phone}
                                </span>
                            )}
                            {register_errors_edit?.phone2_greater && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone2_greater && register_errors_edit.phone2_greater}
                                </span>
                            )}

                            {register_errors_edit?.phone2_less && (
                                <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                    {register_errors_edit.phone2_less && register_errors_edit.phone2_less}
                                </span>
                            )}
                        </Form.Group>
                        
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridWebsite" className='mt-2'>
                            <Form.Label>Company Website</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.company_website} name="company_website" placeholder="Company Website.."/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridGST" className='mt-2'>
                            <Form.Label>Company GST No.</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.company_GST} name="company_GST" placeholder="GST No..."/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                            <Form.Label>Company Address</Form.Label>
                            <Form.Control as="textarea" onChange={handleChange} value={formData.company_add} name="company_add" rows={3} placeholder="Company Address.." required/>
                        </Form.Group>
                            
                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2 mb-2'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" onChange={handleChange} value={formData.username} name="username" placeholder="username..." readOnly disabled/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridCompanyName" className='mt-2 mb-2'>
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="text" onChange={handleChange} name="password" value={formData.password} placeholder="New Password..."/>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="formGridPassword" className='mt-2'>
                                        <Form.Label>Exp date</Form.Label>
                                            <div className="date-picker-container">
                                                <i className='calendar-icon fa fa-calendar'></i>
                                                <DatePicker
                                                    selected={formData.expire_date}
                                                    onChange={handleExptChange}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Receipt Date"
                                                    className='form-control'
                                                    required
                                                />
                                            </div>
                                    </Form.Group>

                        <Form.Group as={Col} xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" controlId="exampleForm.ControlTextarea1" className='mt-2'>
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
                </div>
            </div>
            {/* Edit User Profile End */}
        </div>
    </>
  )
}

export default Alluser
