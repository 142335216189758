import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loginimg from '../../images/login.jpg'
import Logo from '../../images/logo.png'
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { useNavigate,Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerAPI } from '../../redux/actionCreation';
function Register() {
    useEffect(() => {
        // Check if user is already logged in
        const token = localStorage.getItem('tokan');
        if (token) {
            // Display SweetAlert message and redirect to home page
            Swal.fire({
                icon: 'error',
                title: 'Already Logged In',
                text: 'You are already logged in. Please log out first.',
            }).then(() => {
                navigate('/');
            });
        }
    }, []);
    // Passwo
    const navigate = useNavigate();
    const [passwordToggle, setPasswordToggle] = useState(true);
    const error = useSelector(state => state.registrationReducres.error && state.registrationReducres.error.register_errors);
    const dispatch = useDispatch();
    // Password Visible Function Start
    const passwordVisible = () =>{
        setPasswordToggle(!passwordToggle)
      }
    // Password Visible Function End

    const [formData, setFormData] = useState({  
        name: '',
        company_name: '',
        email: '',
        phone_number: '',
        phone_number_2: '',
        company_website: '',
        company_GST: '',
        company_add: '',
        username: '',
        password: '',
        // Add more form fields as needed
      });


      const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const res = await dispatch(registerAPI(formData));
          if(res?.type == "ADD_DATA_SUCCESS"){
            navigate("/login");
          }
          else {
            // Show error SweetAlert
              await Swal.fire({
                  icon: 'error',
                  title: 'Some Error',
                  text: 'Please check your form. Some fields are not filled in properly.',
              });
          }
        } catch (error) {
        //   console.error('Error:', error);
        }

      };

        const handleChange = event => {
            const { name, value } = event.target;
            setFormData(prevState => ({
              ...prevState,
              [name]: value
            }));
          };


  return (
    <>
      <Container fluid>
        <Row>
            <div className='login-wrapper'>
                <div className="login-content">
                    <div className="login-userset">
                        <div className="login-logo">
                            <img src={Logo} alt="login" className='w-100'/>
                        </div>
                        <div className="login-userheading">
                            <h3>Create an Account</h3>
                        </div>
                        <Form onSubmit={handleSubmit}>
                        
                            <div className="form-login">
                                <Form.Label>Full Name</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="text"placeholder="Enter your Full Name" name="name" value={formData.name} onChange={handleChange} required/>
                                    <span className="fas toggle-user fa-user"></span>
                                </div>
                                {error?.cust_name && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                      {error.cust_name && error.cust_name}
                                    </span>
                                  )}
                            </div>

                            <div className="form-login">
                                <Form.Label>Company Name</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="text" placeholder="Enter your Company Name" name="company_name" value={formData.company_name} onChange={handleChange} required/>
                                    <span className="fas toggle-user fa-building"></span>
                                </div>
                            </div>

                            <div className="form-login">
                                <Form.Label>Email</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="email" placeholder="Enter your Email" name="email" value={formData.email} onChange={handleChange}   required/>
                                    <span className="fas toggle-user fa-envelope"></span>
                                </div>
                                {error?.email &&(
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                      {error.email && error.email}
                                    </span>
                                  )}
                            </div>

                            <div className="form-login">
                                <Form.Label>Phone Number 1</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="number" min={0} placeholder="Enter your Phone Number 1" name="phone_number" value={formData.phone_number} onChange={handleChange} required/>
                                    <span className="fas toggle-user fa-phone"></span>
                                </div>
                                {error?.phone_greater &&(
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.phone_greater && error.phone_greater}
                                    </span>
                                )}
                                {error?.phone_less &&(
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.phone_less && error.phone_less}
                                    </span>
                                )}
                                {error?.phone && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.phone && error.phone}
                                    </span>
                                )}
                            </div>

                            <div className="form-login">
                                <Form.Label>Phone Number 2</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="number" min={0} placeholder="Enter your Phone Number 2" name="phone_number_2" value={formData.phone_number_2} onChange={handleChange}/>
                                    <span className="fas toggle-user fa-phone"></span>
                                </div>
                                {error?.phone2_greater && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.phone2_greater && error.phone2_greater}
                                    </span>
                                )}
                                {error?.phone2_less && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.phone2_less && error.phone2_less}
                                    </span>
                                )}
                                {error?.phone && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.phone && error.phone}
                                    </span>
                                )}
                            </div>

                            <div className="form-login">
                                <Form.Label>Company Website</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="text" placeholder="Enter your Company Website" name="company_website" value={formData.company_website} onChange={handleChange}/>
                                    <span className="fas toggle-user fa fa-globe"></span>
                                </div>
                            </div>

                            <div className="form-login">
                                <Form.Label>Company GST</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="text" placeholder="Enter your Company GST" name="company_GST" value={formData.company_GST} onChange={handleChange}/>
                                    <span className="fas toggle-user fa-file"></span>
                                </div>
                            </div>

                            <div className="form-login">
                                <Form.Label>Company Full Address</Form.Label>
                                <div className="pass-group">
                                    <Form.Control as="textarea" rows={3} placeholder="Enter your Company Address" value={formData.company_add} onChange={handleChange} name="company_add" required/>
                                    <span className="fas toggle-user fa fa-address-card"></span>
                                </div>
                            </div>

                            <div className="form-login">
                                <Form.Label>Username</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="text" placeholder="Enter your Username" name="username" value={formData.username} onChange={handleChange} required/>
                                    <span className="fas toggle-user fa-user"></span>
                                </div>
                                {error?.username && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.username && error.username}
                                    </span>
                                )}
                                {error?.username_len && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.username_len && error.username_len}
                                    </span>
                                )}
                            </div>

                            <div className="form-login mt-3">
                                <Form.Label>Password</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type={`${passwordToggle ? 'password' : 'text'}`} className="pass-input" placeholder="Password" value={formData.password} onChange={handleChange} name="password" required/>
                                    <span className={`fas toggle-password fa-${passwordToggle ? 'eye' : 'eye-slash'}`} onClick={passwordVisible}></span>
                                </div>
                                {error?.password && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                        {error.password && error.password}
                                    </span>
                                )}
                            </div>

                            <div className="register-accept mt-3 mb-3">
                                <Form.Check required  className='d-inline-block mx-1'/> I acknowledge <Link to="https://packersbilling.in/terms-and-conditions.php" className='d-inline-block mx-1' target='_blank'>Terms of Service</Link> and <Link to="https://packersbilling.in/privacy-policy.php" className='d-inline-block mx-1' target='_blank'>Privacy Policy.</Link>
                            </div>

                            <div className="form-login">
                                <input type="submit" name="submit" className="btn btn-login" value="Registration" />
                            </div>
                            

                        </Form>
                            <div className="signinform text-center">
                                <h4>Already a user? <Link to="/login" className="hover-a">Login In</Link></h4>
                            </div>
                    </div>
                </div>
                <div className="login-img">
                    <img src={Loginimg} alt="login-right"/>
                </div>
            </div>
        </Row>
      </Container>
    </>
  )
}

export default Register