// reducer.js
const initialState = {
    data: null,
    loading: false,
    error: null
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_DATA_SUCCESS':
        console.log('Reducer',action.payload);
        return { ...state, loading: false, data: action.payload, error: null };
      case 'ADD_DATA_FAILURE':
        console.log('Reducer',action.payload);
        return { ...state, loading: false, data: null, error: action.payload };
      default:
        return state;
    }
  };
  
  export default reducer;
  