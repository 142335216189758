import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loginimg from '../../images/login.jpg'
import Logo from '../../images/logo.png'
import Form from 'react-bootstrap/Form';
import { useNavigate,Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAPI } from '../../redux/actionCreation';
import Loader from '../Loader';
function Login() {
  const [loading, setLoading] = useState(false);
  const [passwordToggle, setPasswordToggle] = useState(true);
    const navigate = useNavigate();
    
     // if User login so user cant access login page
     useEffect(()=>{
         if(localStorage.getItem('tokan')){
             navigate('/');
         }
     }, []);
     // Password Visible Function Start
      const passwordVisible = () =>{
        setPasswordToggle(!passwordToggle)
      }
    // Password Visible Function End
    const logindata = useSelector(state => state.loginReducres.error && state.loginReducres.error.login_errors);
    const error = useSelector(state => state.loginReducres.error);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({  
        username: '',
        password: ''
      });
      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); 
        try {
          const res = await dispatch(loginAPI(formData));
          if(res?.type == "ADD_DATA_SUCCESS"){
            navigate("/update-company");
          }
        } catch (error) {
        //   console.error('Error:', error);
        }finally {
          setLoading(false); // Set loading to false when the login request is complete (either success or failure)
        }

      };

      const handleChange = event => {
        const { name, value } = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
      
  return (
    <>
      <Container fluid>
        <Row>
            <div className='login-wrapper'>
                <div className="login-content">
                    <div className="login-userset">
                        <div className="login-logo">
                            <img src={Logo} alt="login" className='w-100'/>
                        </div>
                        <div className="login-userheading">
                            <h3>Log In</h3>
                        </div>
                        <Form onSubmit={handleSubmit}>
                        {logindata?.expire_date && (
                            <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                              {logindata.expire_date && logindata.expire_date} 
                                  {localStorage.removeItem("token")}
                              </span>
                            )}
                        {error?.error && (
                                    <span className="alert alert-danger d-block mt-1 pt-1 pb-1">
                                      {error.error && error.error}
                                    </span>
                                  )}
                            <div className="form-login">
                                <Form.Label>Username</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type="text" placeholder="Enter your Username" name="username" onChange={handleChange} required/>
                                    <span className="fas toggle-user fa-user"></span>
                                </div>
                            </div>
                            <div className="form-login mt-3">
                                <Form.Label>Password</Form.Label>
                                <div className="pass-group">
                                    <Form.Control type={`${passwordToggle ? 'password' : 'text'}`} className="pass-input" placeholder="Enter your Password" onChange={handleChange} name="password" required/>
                                    <span className={`fas toggle-password fa-${passwordToggle ? 'eye' : 'eye-slash'}`} onClick={passwordVisible}></span>
                                </div>
                            </div>
                            <div className="form-login">
                                <input type="submit" name="submit" className="btn btn-login" value="Log In" />
                            </div>
                        </Form>
                        <div className='text-center'>
                          {loading && <Loader />}
                        </div>
                            <div className="signinform text-center">
                                <h4>Don't have an account? <Link to="/register" className="hover-a">New Ragister</Link>
                                 </h4>
                            </div>
                    </div>
                </div>
                <div className="login-img">
                    <img src={Loginimg} alt="login-right"/>
                </div>
            </div>
        </Row>
      </Container>
    </>
  )
}

export default Login
